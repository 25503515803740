
/*   -=========== Login and registration ===========-   */

.auth-page {
  display: flex;
  height: auto;
  min-height: 100%;

  @media $small-mobile {
    flex-direction: column;
  }
}

.auth-page--logo {
  display: block;
  width: width('i/mobil-market-logo-big.png'); height: height('i/mobil-market-logo-big.png');
  background: url(/i/mobil-market-logo-big.png) no-repeat 0 / contain;
  margin-bottom: 30px;

  @media $small-mobile {
    height: 80px;
    margin-bottom: 20px;
  }
}

.auth-page--text {
  flex-basis: 50%;
  background: url(/i/auth-bg.png) no-repeat 100% / auto 100%;
  padding-top: 120px;
  padding-left: 120px;
  box-sizing: border-box;
  color: $gray;
  font-size: 2.4rem;
  line-height: 1.35;

  @media only screen and (max-width: 1600px) {
    font-size: 2rem;
  }

  @media $notebook {
    background-position: 60% 50%;
  }

  @media $handheld {
    padding-top: 50px;
    padding-left: 50px;
    background-position: 60% 50%;
  }

  @media $landscape-mobile {
    @media $not-small-handheld {
      background: none;
    }
  }

  @media $small-handheld {
    background: none;
    flex-basis: auto;
    padding: 30px 20px;
    font-size: 1.8rem;
  }
}

.auth-page--content {
  flex-basis: 50%;
  background: $blue;
  display: flex;
  justify-content: center;
  align-items: center;

  &, a {
    color: $white;
  }

  @media $landscape-mobile {
    flex-basis: auto;
    display: block;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.auth-page--form-group {
  width: calc(100% - 40px);
  max-width: 460px;

  @media $landscape-mobile {
    margin: auto;
  }
}

.auth-page--caption {
  color: $white;
  margin-bottom: 35px;
}

.auth-page--subcaption {
  margin-top: -30px;
  text-transform: uppercase;
  font-size: 2.4rem;
  margin-bottom: 25px;
}

.auth-page--form-footer {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 15px;

  a {
    text-decoration: none;
  }
}


.sm-auth {
  text-align: center;
  font-size: 0;
  margin-top: 35px;

  .item {
    display: inline-block;
    vertical-align: middle;
  }

  .item + .item {
    margin-left: 40px;
  }

  for $social in 'vk' 'fb' 'twit' {
    .item_{$social} {
      background: url('/i/sm-' + $social + '-white.png') no-repeat 50%;
      width: width('i/sm-' + $social + '-white.png');
      height: height('i/sm-' + $social + '-white.png');
    }
  }
}
