
/*   -=========== Compare ===========-   */

.compare-page {
  .vitrine {
    .product {
      position: relative;
      width: 233px;
      padding-bottom: 20px;

      @media $small-mobile {
        width: calc(50vw - 20px);
      }
    }

    .product--image {
      margin-top: 0;
    }

    .product--buy-btn {
      font-size: 0;
    }

    .product--name {
      margin-bottom: 58px;

      @media $small-mobile {
        margin-bottom: 78px;
      }
    }

    .product--footer {
      bottom: 20px;
    }

    .product--remove {
      width: 20px; height: 20px;
      position: absolute;
      top: 15px; right: 0;
      transform: rotate(-45deg) translateY(-50%);
      opacity: .5;
      transition: all .25s ease;
      font-size: 0;
      background: none;

      &:before, &:after {
        content: '';
        display: block;
        background: $gray;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
      }

      &:before {
        width: 15px; height: 2px;
      }

      &:after {
        width: 2px; height: 15px;
      }

      &:hover {
        opacity: 1;
      }
    }

    .product--footer {
      @media $small-mobile {
        flex-direction: column;
        margin-top: 1em;
      }
    }
  }
}

.compare-page--caption {
  @extend .h1;
  margin-bottom: 10px;
}

.compare-page--content {
  background: $back-gray;
  padding: 40px;

  @media $small-mobile {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.compare-page--right {
  display: block;
  position: relative;
  padding: 0 0 40px;

  .ps__rail-x {
    opacity: 1;
  }

  .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background: $blue !important;
  }
}

.compare-page--tabs-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;

  @media $small-mobile {
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin-right: 42px;

    @media $small-mobile {
      margin-right: 25px;
      margin-bottom: 1em;
    }
  }

  a {
    @extend .btn, .blue-bordered-btn, .rounded-btn;
    line-height: 40px;
    font-size: 1.4rem;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;

    .counter {
      position: absolute;
      left: 100%; top: 0;
      margin-top: -2px;
      margin-left: 2px;
      color: $gray;
      line-height: 1;
    }
  }

  .is-active a {
    background: $blue;
    color: $white;
    cursor: default;
  }
}

.compare-page--clear-button {
  @extend .btn;
  position: relative;
  padding-right: 35px;
  margin-bottom: 30px;
  color: $gray;
  background: none;

  .icon {
    width: 20px; height: 20px;
    position: absolute;
    right: 0; top: 50%;
    margin-top: -2px;
    transform: rotate(-45deg) translateY(-50%);

    &:before, &:after {
      content: '';
      display: block;
      background: $gray;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 15px; height: 2px;
    }

    &:after {
      width: 2px; height: 15px;
    }
  }
}

.compare-page--content-layout {
  display: flex;
  margin-bottom: 50px;
}

.compare-page--left {
  width: 250px;
  flex-shrink: 0;

  th {
    padding-right: 20px;
  }

  th {
    vertical-align: bottom;
  }

  td {
    font-weight: bold;
  }

  @media $small-mobile {
    display: none;
  }
}

.compare-page--right {
  th {
    padding-right: 35px;

    @media $small-mobile {
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.compare-page--table th {
  text-align: left;
  font-weight: normal;
  padding-bottom: 20px;
}

.compare-page--table td {
  font-size: 1.6rem;
  padding: 15px 35px 15px 0;
  color: $gray;

  @media $small-mobile {
    padding: 5px 10px 5px 0;
    font-size: 1.2rem;
  }
}

.compare-page--table-button {
  @extend .btn, .rounded-btn;
  bordered-btn(2px, $white);
  background: transparent;
  line-height: 40px;
  text-align: left;
  color: $gray;
  width: 172px;
  padding-left: 25px;
  margin-top: 22px;
  font-size: 1.4rem;

  &.is-active {
    background: $white;
    cursor: default;
  }
}

.compare-page--mobile-param-label {
  border-top: 1px solid $light-gray;

  @media $not-small-mobile {
    display: none !important;
  }

  td {
    padding-top: 10px;
    padding-bottom: 0;
  }
}
