
/*   -=========== Favorite ===========-   */

.favorite-page {
  .vitrine {
    background: $back-gray;
    padding-top: 30px;
    padding-bottom: 100px;

    @media $small-mobile {
      padding-top: 20px;
      padding-bottom: ($grid-gap / 2);
    }

    .product {
      position: relative;
    }

    .product--remove {
      position: absolute;
      top: 10px; right: 10px;
      background: none;
      font-size: 1.2rem;
      font-weight: bold;
      color: $blue;
      opacity: .5;
      transition: all .25s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.favorite-page--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.favorite-page--caption {
  @extend .h1;
}

.favorite-page--clear {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 1.3rem;

  @media $mobile {
    font-size: 1rem;
  }
}
