
/*   -=========== Card ===========-   */

.product-card {
  main-grid();
  align-items: start;
  grid-template-rows: auto auto auto 1fr auto;
  padding-left: 'calc(%s + 2%)' % ($grid-gap / 2);
  padding-right: 'calc(%s + 2%)' % ($grid-gap / 2);
  color: $gray;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;

  @media $small-mobile {
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
  }

  .breadcrumbs {
    grid-column: 4 / 10;
    grid-row: 1 / 2;

    @media $notebook {
      grid-column: 5 / 11;
      margin-left: -($grid-gap);
    }

    @media $tablet {
      grid-column: 1 / 11;
    }
  }

  .vitrine:not(.is-expanded) {
    .product {
      @media only screen and (max-width: 1300px) {
        &:nth-child(12) ~ .product {
          display: none;
        }
      }

      @media $landscape-tablet {
        &:nth-child(9) ~ .product {
          display: none;
        }
      }

      @media $small-handheld {
        &:nth-child(6) ~ .product {
          display: none;
        }
      }

      @media $small-mobile {
        &:nth-child(3) ~ .product {
          display: none;
        }
      }
    }
  }
}

.product-card--caption {
  grid-column: 4 / 10;
  grid-row: 2 / 3;

  @media $notebook {
    grid-column: 1 / 11;
  }

  @media $handheld {
    grid-column: 1 / 11;
    grid-row: 2 / 3;
  }

  @media $mobile {
    grid-row: 1 / 2;
  }
}

.product-card--gallery-column {
  grid-column: 1 / 4;
  grid-row: 2 / 5;
  position: relative;

  @media $not-small-mobile {
    overflow: hidden; // fixes ff bug with gallery sliders width
    max-width: 22vw;

    @media $less-than-wrapper {
      max-width: 26vw;
    }

    @media $notebook {
      @media $desktop {
        max-width: 31vw;
      }
    }

    @media $handheld {
      max-width: 100%;
    }
  }

  @media $notebook {
    margin-left: $grid-gap;
    margin-right: -($grid-gap);

    @media $desktop {
      grid-row: 3 / 5;
    }
  }

  @media $tablet {
    grid-column: 1 / 11;
    grid-row: 3 / 5;
    grid-template-rows: 1fr 1fr 1fr;
    main-grid();
    padding-left: 0;
    padding-right: 0;
  }

  @media $mobile {
    grid-column: 1 / 11;
    grid-row: 2 / 3;
  }

  @media $small-mobile {
    img {
      max-height: 80vw;
    }
  }
}

.product-card--gallery_video {
  @media $tablet {
    grid-column: 5 / 11;
    grid-row: 2 / 3;
  }
}

.product-card--gallery-container {
  @media $tablet {
    grid-column: 1 / 11;
    grid-row: 1 / 4;
    main-grid();
    padding-left: 0;
    padding-right: 0;

    .product-card--gallery {
      grid-column: 5 / 11;
    }
  }

  @media $small-mobile {
    margin-left: -($grid-gap / 2);
    margin-right: -($grid-gap / 2);

    img {
      max-width: calc(100vw - 30px);
    }
  }
}

.product-card--icons {
  product-icons-prototype();
  position: absolute;
  left: 10px; top: 10px;
  z-index: 10;
}

.product-card--main-column {
  grid-column: 4 / 10;
  grid-row: 3 / 4;

  @media $notebook {
    grid-column: 5 / 11;
    margin-left: -($grid-gap);
  }

  @media $tablet {
    grid-column: 1 / 11;
    grid-row: 5 / 6;
  }

  @media $mobile {
    grid-column: 1 / 11;
    grid-row: 3 / 4;
  }

  @media $small-mobile {
    margin-top: 30px;
  }
}

.product-card--variants-column {
  grid-column: 10 / 13;
  grid-row: 3 / 4;
  align-self: stretch;
  padding: 40px 35px;

  @media $bigger-than-wrapper {
    background: $back-gray;
  }

  @media $less-than-wrapper {
    grid-column: 4 / 10;
    grid-row: 4 / 5;
    border-top: 2px solid $back-gray;
    margin-top: 25px;
    padding-top: 20px;
    padding-left: 65px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media $notebook {
    grid-column: 5 / 11;
    margin-left: -($grid-gap);
  }

  @media $tablet {
    grid-column: 1 / 11;
    grid-row: 6 / 7;
    padding-right: 0;
  }

  @media $small-handheld {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  @media $mobile {
    grid-column: 1 / 11;
    padding-left: 0;
    padding-right: 0;
  }
}

.product-card--variants-column_empty {
  background: transparent;
  padding: 0;
}

.product-card--tabs {
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  main-grid();
  align-items: start;

  @media $less-than-wrapper {
    grid-row: 5 / 6;
  }

  @media $tablet {
    grid-row: 7 / 8;
  }

  @media $small-handheld {
    display: block;
  }

  .tabs-nav,
  .tabs-panel {
    grid-column: 4 / 13;

    @media $less-than-wrapper {
      grid-column: 1 / 11;
    }
  }

  .tabs-nav {
    grid-row: 1 / 2;
  }

  .tabs-panel {
    grid-row: 2 / 3;
  }

  .tabs-panel_accessories {
    grid-column-start: 1;
  }
}

.product-card--main-image {
  text-align: center;
  margin-bottom: 35px;

  @media $tablet {
    grid-column: 1 / 5;
  }

  img {
    margin: auto;
  }
}

.product-card--caption {
  color: $blue;
  margin-bottom: 25px;

  @media $small-mobile {
    padding-right: 62px;
  }
}

.product-card--caption-category,
.product-card--caption-text {
  display: block;
}

.product-card--caption-category {
  @extend .h1;
  margin-bottom: 5px;

  @media $small-mobile {
    font-size: 1.6rem;
  }
}

.product-card--caption-text {
  @extend .h2;
  font-weight: normal;

  @media $small-mobile {
    font-size: 1.4rem;
  }
}

card-grid() {
  display: grid;
  grid-template-columns: 1fr 1fr 30%;
  padding: 10px 20px 10px 30px;

  .cell_two-columns {
    grid-column-end: span 2;
  }

  @media $tablet {
    .cell_tablet-one-column {
      grid-column-end: span 1;
    }

    .cell_tablet-two-columns {
      grid-column-end: span 2;
    }
  }

  @media $portrait-tablet {
    .cell_portrait-tablet-one-column {
      grid-column-end: span 1;
    }

    .cell_portrait-tablet-two-columns {
      grid-column-end: span 2;
    }
  }

  .cell_three-columns {
    grid-column-end: span 3;
  }

  .cell_justify {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.product-card--avail-row {
  card-grid();
  align-items: center;
  background: $back-gray;
  color: $gray;
  font-size: 1.4rem;
  font-weight: bold;

  @media $small-mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.product-card--avail {
  padding-left: 32px;
}

.product-card--avail_avail {
  background: url(/i/check-icon.svg) no-repeat 0 50% / contain;
}

.product-card--article,
.product-card--fav-btn,
.product-card--compare-btn {
  display: inline-block;
  vertical-align: middle;
}

.product-card--article {
  flex: 1;
}

.product-card--fav-btn {
  width: width('i/heart-icon-blue.png'); height: height('i/heart-icon-blue.png');
  background: url(/i/heart-icon.svg) no-repeat 50%;

  &.active {
    background-image: url(/i/heart-icon-blue.svg);
  }

  @media $small-mobile {
    position: absolute;
    top: 10px; right: 52px;
  }
}

.product-card--compare-btn {
  width: width('i/compare-icon-gray.svg'); height: 23px;
  background: url(/i/compare-icon-gray.svg) no-repeat 50%;
  margin-left: 25px;

  &.active {
    background-image: url(/i/compare-icon-blue.svg);
  }

  @media $small-mobile {
    position: absolute;
    top: 12px; right: 12px;
  }
}

.product-card--price-row {
  card-grid();
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;

  @media $mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;

    .cell:nth-child(1) {
      order: 2;
    }

    .cell:nth-child(2) {
      order: 3;
    }

    .cell:nth-child(3) {
      order: 1;
      flex-basis: 100%;
      margin-bottom: 15px;
    }
  }
}

.product-card--buttons-group {
  display: inline-block;
  /* text-align: center; */

  @media $not-mobile {
    margin-left: -30px;
  }
}

.product-card--buy-btn {
  buy-btn-prototype();
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 10px;
  width: 150px;
  white-space: nowrap;
  background-position: 24px 50%;

  @media $mobile {
    @media $not-small-mobile {
      width: 130px;
    }
  }

  @media $notebook {
    width: 140px;
  }
}

.product-card--fast-order-link {
  @extend .btn;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4;
  color: $gray;
  margin-top: 20px;
  padding-left: 49px;
  margin-left: 19px;
  background: url(/i/fast-order-icon.svg) no-repeat 0 50% / 28px auto;
  line-height: 23px;
  transition: none;

  @media $mobile {
    font-size: 1.2rem;
  }

  &:hover {
    color: $blue;
    background-image: url(/i/fast-order-icon-blue.svg);
  }
}

.product-card--price {
  font-family: $second-font;
  font-size: 2.6rem;
  font-weight: bold;
  position: relative;

  .currency {
    font-family: $master-font;
  }
}

.product-card--last-price-label {
  color: $light-gray;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  left: 0; bottom: 100%;
  margin-bottom: 5px;
}

.product-card--old-price {
  font-family: $second-font;
  font-size: 1.4rem;
  text-decoration: line-through;
  color: $light-gray;

  .currency {
    font-family: $master-font;
  }
}

@media $not-mobile {
  .product-card--spinner,
  .product-card--price-group:not(.product-card--price-group_centered) {
    margin-top: -22px;
  }
}

@media $mobile {
  .product-card--spinner {
    margin-top: -44px;
  }
}

.product-card--info-row {
  card-grid();
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 2px solid $back-gray;

  @media $mobile {
    padding-left: 0;
    padding-right: 0;
  }

  @media $small-mobile {
    grid-template-columns: 1fr;
  }
}

.product-card--info-row_centered {
  align-items: center;
}

@media $small-handheld {
  .product-card--info-row_small-handheld-two-columns {
    grid-template-columns: 1fr 1fr;

    @media $small-mobile {
      grid-template-columns: 1fr;
    }
  }
}

.product-card--credit-caption,
.product-card--delivery-caption,
.product-card--warranty-caption,
.product-card--short-params-caption {
  font-size: 1.4rem;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 6px;

  @media $not-small-mobile {
    padding-left: 45px;
    margin-left: -7px;
  }

  @media $small-mobile {
    padding-left: 40px;
  }
}

.product-card--credit-caption {
  background: url(/i/credit-icon.svg) no-repeat 0 50%;
}

.product-card--delivery-caption {
  background: url(/i/delivery-icon.svg) no-repeat 0 50%;
  margin-bottom: 10px;
  position: relative;
}

.product-card--warranty-caption {
  background: url(/i/warranty-icon.svg) no-repeat 0 50%;
  background-size: width('i/warranty-icon.svg') auto;

  @media $small-mobile {
    br {
      display: none;
    }
  }
}

.product-card--short-params-caption {
  margin-bottom: 10px;

  @media $mobile {
    margin-left: 0;
    padding-left: 0;
  }
}

.product-card--credit-more-link {
  font-size: 1.2rem;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: url(/i/attention-icon.svg) no-repeat 100% 50%;
  text-decoration: none;

  @media $small-mobile {
    display: inline-block;
    margin-top: 10px;
  }
}

.product-card--delivery-text {
  font-size: 1.4rem;
  line-height: 1.5;

  @media $not-small-mobile {
    padding-left: 38px;
  }

  @media $small-mobile {
    font-size: 1.2rem;
  }
}

.product-card--delivery-more-link {
  position: absolute;
  right: 20px; top: 4px;
  font-size: 1.2rem;
  font-weight: normal;
  color: $blue;
  text-decoration: none;

  @media $small-mobile {
    top: 7px;
  }
}

.rostest-icon {
  display: inline-block;
  /* padding-left: 42px; */
  position: relative;
  font-weight: bold;

  @media $small-mobile {
    font-size: 1.4rem;
    margin-top: 10px;

    br {
      display: none;
    }
  }

  /* &:before {
    content: 'РСТ';
    position: absolute;
    left: 0; top: 0;
  } */
}

.product-card--not-avail-placeholder {
  font-weight: bold;
  font-size: 1.4rem;
}

.product-card--info-row_params {
  padding-bottom: 0;

  @media $small-handheld {
    padding-right: 0;
  }
}

.product-card--short-params {
  font-size: 1.4rem;
  line-height: 1.5;
  padding-left: 38px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media $mobile {
    padding-left: 0;
  }

  .item {
    flex-basis: 31.5%;

    dt, dd {
      display: inline;
    }

    dt {
      margin-right: 10px;
    }
  }

  @media $desktop {
    .item:nth-child(even) {
      margin-left: 30%;
    }
  }

  @media $landscape-tablet {
    .item {
      margin-left: 2%;
    }

    .item:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @media $small-handheld {
    @media $not-mobile {
      .item {
        box-sizing: border-box;
        flex-basis: 50%;
      }

      .item:nth-child(odd) {
        padding-right: 2%;
      }
    }
  }

  @media $mobile {
    .item {
      flex-basis: 100%;
    }

    .item_dummy {
      display: none;
    }
  }
}

.product-card--params-link {
  display: inline-block;
  color: $blue;
  margin-top: 10px;
  text-decoration: none;
}

.product-card--variants-group {
  @media $portrait-tablet {
    flex: 1;
  }

  @media $small-handheld {
    flex-basis: 50%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-bottom: 25px;
  }

  @media $mobile {
    flex-basis: 100%;
    padding-right: 0;
  }
}

.product-card--variants-group + .product-card--variants-group {
  @media $bigger-than-wrapper {
    margin-top: 35px;
  }

  @media $less-than-wrapper {
    @media $not-small-handheld {
      margin-left: 15px;
    }
  }
}

.product-card--variants-caption {
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;

  @media $less-than-wrapper {
    text-transform: lowercase;
    margin-bottom: 20px;
  }
}

.product-card--variants {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;

  .item {
    @extend .btn;
    bordered-btn(2px, $yellow);
    line-height: 33px;
    padding: 0 10px;
    font-size: 1.4rem;
    color: #959698;
    margin-right: 10px;
    margin-bottom: 10px;

    &.active,
    &:hover {
      background: $yellow;
      color: $white;
    }
  }
}

.product-card--variants_colors {
  .item {
    box-shadow: none;
    width: 20px; height: @width;
    border-radius: 50%;
    margin-right: 15px;
    padding: 0;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 30px; height: @width;
      box-sizing: border-box;
      border: 2px solid $yellow;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.active:before,
    &:hover:before {
      opacity: 1;
    }

    &[title="белый"] {
      box-shadow: inset 0 0 0 2px $black;
    }
  }
}

.product-card--gallery {
  margin-bottom: 25px;
}

.product-card--gallery-caption,
.product-card--sharing-caption {
  font-size: 1.4rem;
  font-weight: bold;
  color: $gray;
  margin-bottom: 15px;
  margin-left: 5px;
}

.product-card--gallery-slider {
  position: relative;

  &:not(.no-arrows) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media $small-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .item {
    position: relative;
    cursor: pointer;
    outline: none;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &:after {
      box-sizing: border-box;
      content: '';
      display: block;
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      border: 2px solid transparent;
      transition: border-color .25s ease;
    }

    img {
      width: calc(100% - 10px); height: calc(100% - 10px);
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }

  .product-card--gallery:not(.product-card--gallery_video) & {
    .slick-current .item {
      &:after {
        border-color: $blue;
      }
    }
  }

  .slick-arrow {
    @extend .btn;
    width: 30px; height: 30px;
    background: transparent;
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:before {
      content: '';
      display: block;
      width: 10px; height: @width;
      border-top: 2px solid $gray;
      position: absolute;
    }
  }

  .slick-disabled {
    opacity: .25;
    cursor: default;
  }

  .slick-prev {
    left: 0;

    &:before {
      left: 50%;
      border-left: 2px solid $gray;
      transform: rotate(-45deg);
      transform-origin: left top;
      margin-left: -5px;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      right: 50%;
      border-right: 2px solid $gray;
      transform: rotate(45deg);
      transform-origin: right top;
      margin-right: -5px;
    }
  }

  .item_video {
    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/youtube-icon.png) no-repeat 50%;
      position: absolute;
      left: 0; top: 0;
      transition: opacity .25s ease;
    }

    &:before {
      padding-bottom: 78%;
    }

    &:hover:after {
      opacity: .5;
    }
  }
}

@media $small-mobile {
  .product-card--gallery-slider_video {
    margin-left: -($grid-gap / 2);
    margin-right: -($grid-gap / 2);

    .item img {
      width: 100%; height: 100%;
    }
  }
}

.product-card--sharing {
  @media $tablet {
    grid-column: 5 / 11;
    grid-row: 3 / 4;
    align-self: self-end;
    margin-bottom: 45px;
  }

  @media $mobile {
    @media $not-small-mobile {
      margin-bottom: 30px;
    }
  }

  .ya-share2 {
    margin-left: 5px;

    ul {
      display: flex;
    }

    li {
      list-style: none;
      display: inline-block;
    }

    li + li {
      margin-left: 15px;
    }

    a {
      display: block;
      font-size: 0;
    }
  }

  .ya-share2__item_service_vkontakte a {
    width: width('i/sm-vk.svg'); height: height('i/sm-vk.svg');
    background: url(/i/sm-vk.svg) no-repeat 50%;
  }

  .ya-share2__item_service_facebook a {
    width: width('i/sm-fb.svg'); height: height('i/sm-fb.svg');
    background: url(/i/sm-fb.svg) no-repeat 50%;
  }

  .ya-share2__item_service_twitter a {
    width: width('i/sm-twit.svg'); height: height('i/sm-twit.svg');
    background: url(/i/sm-twit.svg) no-repeat 50%;
  }

  .ya-share2__item_service_gplus a {
    width: width('i/sm-gplus.svg'); height: height('i/sm-gplus.svg');
    background: url(/i/sm-gplus.svg) no-repeat 50%;
  }

  .ya-share2__item_service_odnoklassniki a {
    width: width('i/sm-ok.svg'); height: height('i/sm-ok.svg');
    background: url(/i/sm-ok.svg) no-repeat 50%;
  }
}


.product-card--tabs {
  margin-top: 60px;
  font-size: 1.4rem;
  /* padding-left: 68px; */
  padding-left: 0;
  padding-right: 0;

  /* @media $less-than-wrapper {
    padding-left: 0;
  }

  @media $notebook {
    padding-left: $grid-gap;
    padding-right: 38px;
  } */

  @media $mobile {
    margin-top: 10px;
  }

  > .tabs-nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid $back-gray;
    margin-bottom: 35px;

    @media $small-handheld {
      display: none;
    }

    li {
      margin-bottom: -3px;
      list-style: none;
    }

    a {
      display: block;
      padding: 20px 0;
      position: relative;
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      color: $gray;

      &:after {
        content: '';
        display: block;
        width: 100%; height: 6px;
        background: $blue;
        position: absolute;
        bottom: 0; left: 0;
        opacity: 0;
        transition: opacity .25s ease;
      }

      &:hover {
        color: $blue;
      }
    }

    .is-active a {
      color: $blue;

      &:after {
        opacity: 1;
      }
    }
  }

  .tabs-panel--mobile-heading {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 3px solid $back-gray;
    color: $gray;
    transition: all .25s ease;

    @media $not-small-handheld {
      display: none;
    }
  }


  @media $small-handheld {
    .tabs-panel {
      display: block !important;
    }

    .tabs-panel--body {
      overflow: hidden;
      transition: all .25s ease;
    }

    .tabs-panel:not(.is-expanded) .tabs-panel--body {
      opacity: 0;
      max-height: 0;
    }

    .tabs-panel.is-expanded {
      .tabs-panel--mobile-heading {
        border-bottom: 6px solid $blue;
        color: $blue;
      }

      .tabs-panel--body {
        max-height: 10000px;
      }
    }

    @media $not-mobile {
      .tabs-panel.is-expanded .tabs-panel--body {
        padding-top: 35px;
        padding-bottom: 35px;
      }
    }

    @media $mobile {
      .tabs-panel.is-expanded .tabs-panel--body {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .tabs-panel--body {
        margin-left: -($grid-gap / 2);
        margin-right: -($grid-gap / 2);
        padding-left: ($grid-gap / 2);
        padding-right: ($grid-gap / 2);
      }
    }
  }
}

.text-container + .product-card--params {
  margin-top: 2em;
}

.product-card--params-caption {
  @extend .h3;
  text-transform: uppercase;
}

.product-card--params-list {
  width: 100%;

  th {
    text-align: left;
    font-weight: normal;
    padding-right: 70px;

    @media only screen and (min-width: 1600px) {
      width: 30%;
    }

    @media $handheld {
      padding-right: 20px;
    }
  }

  td, th {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.product-card--params-subcaption th {
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 10px;
}

.product-card .cr .mc-noreviews {
  line-height: 1.5 !important;
}


.product-card--description-tab {
  font-size: 1.3rem;
  line-height: 1.5;
}


.questions--empty-body {
  text-align: center;

  @media $not-small-mobile {
    margin-bottom: -57px;
  }
}

.questions-list {
  .question {
    background: $back-gray;
    color: $gray;
  }

  .question + .question {
    margin-top: 1em;
  }

  .question--body,
  .answer {
    padding: 20px 30px;
  }

  .answer {
    border-top: 1px solid $light-gray;
    padding-left: 60px;
  }

  .question--author-name,
  .answer--author-name {
    color: $black;
    font-weight: bold;
  }

  .question--date,
  .answer--date {
    font-size: 1.2rem;
    margin-top: .5em;
  }

  .question--text,
  .answer--text {
    margin-top: 1em;
  }

  .question--text {
    position: relative;

    &:before {
      content: '?';
      display: block;
      color: $blue;
      font-size: 1.2em;
      font-weight: bold;
      position: absolute;
      left: -15px; top: -.2em;
    }
  }
}

.product-card--ask-button {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 20px;
  text-transform: lowercase;
  margin-top: 2em;
}


.product-card--description-note {
  background: $back-gray;
  font-size: 1.4rem;
  padding: 40px 50px 50px;
  position: relative;
  grid-column: 4 / 13;
  grid-row: 6 / 7;

  @media $less-than-wrapper {
    grid-column: 1 / 10;
    grid-row: 6 / 7;
  }

  @media $notebook {
    grid-column-end: 11;
  }

  @media $tablet {
    grid-column-end: 11;
    grid-row: 8 / 9;
  }

  @media $mobile {
    grid-column-end: 11;
  }

  @media $desktop {
    margin-right: -100vw;
    margin-top: 50px;
  }

  @media $handheld {
    padding-top: 75px;
    margin-top: 75px;
  }

  @media $small-mobile {
    margin-left: -($grid-gap / 2);
    margin-right: -($grid-gap / 2);
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
    padding-bottom: 35px;
  }

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &:before {
    width: width('i/asterisk-yellow.png'); height: height('i/asterisk-yellow.png');
    background: url(/i/asterisk-yellow.png) no-repeat 50%;
    left: -93px; top: 18px;
    z-index: -1;

    @media $handheld {
      width: 89px; height: 86px;
      background-size: contain;
      left: 15px; top: -47px;
    }
  }

  &:after {
    width: width('i/asterisk-blue.png'); height: height('i/asterisk-blue.png');
    background: url(/i/asterisk-blue.png) no-repeat 50%;
    left: -85px; top: -9px;
    z-index: 1;

    @media $handheld {
      width: 89px; height: 86px;
      background-size: contain;
      left: 21px; top: -37px;
    }
  }

  .item {
    padding-top: 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 1000px;

    @media $small-mobile {
      flex-direction: column;
    }

    &:before {
      content: '';
      display: block;
      width: 43px; height: 4px;
      background: $white;
      position: absolute;
      left: 0; top: 0;
    }
  }

  .item--caption {
    color: $blue;
    font-weight: bold;
    width: 300px;
    margin-right: 30px;
    flex-shrink: 0;

    @media $small-mobile {
      margin-right: 0;
      margin-bottom: 1em;
      width: auto;
    }
  }

  .item--text {
    p + p {
      margin-top: 1em;
    }
  }

  .item + .item {
    margin-top: 60px;

    @media $small-mobile {
      margin-top: 25px;
    }
  }
}

.product-card--description-note-caption {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.accessories-tabs {
  main-grid();
  align-items: start;
  padding-left: 0;
  padding-right: 0;

  .tabs-nav {
    grid-column: 1 / 4;

    @media $less-than-wrapper {
      grid-column: 1 / 3;
    }

    li {
      list-style: none;
    }

    li + li {
      margin-top: 1em;
    }

    li a {
      display: block;
      color: $blue;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        display: block;
        width: 10px; height: 10px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
        position: absolute;
        right: 100%;
        margin-right: 30px;
      }

      @media $less-than-wrapper {
        @media $not-mobile {
          padding-left: 45px;

          &:before {
            right: auto; left: 0;
          }
        }
      }

      @media $mobile {
        font-size: 1.2rem;
        text-transform: lowercase;
        padding-left: 25px;

        &:before {
          left: 2px; top: 1.25em;
        }
      }
    }

    li.is-active a {
      color: $black;
    }

    li.is-active a:before {
      transform: rotate(45deg);
      border-color: $black;

      @media $not-mobile {
        top: .8em;
      }
    }

    li:not(.is-active) a:before {
      transform: rotate(135deg);
      top: .6em;
    }
  }

  .tabs-content {
    grid-column: 4 / 13;

    @media $less-than-wrapper {
      grid-column: 3 / 11;
    }
  }
}


.product-card--all-variants-link {
  @extend .btn, .blue-bordered-btn, .rounded-btn;
  line-height: 40px;
  font-size: 1.4rem;
  padding: 0 25px;
  color: $blue;
}

.variants-table {
  font-size: 1.3rem;

  &, a {
    color: $gray;
  }

  a {
    text-decoration: none;
  }

  th {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2rem;
    vertical-align: bottom;
    position: relative;
    user-select: none;

    span {
      display: table;
      width: 1px;
    }
  }

  td {
    padding-top: 17px;
    padding-bottom: 1px;

    @media $not-small-mobile {
      border-bottom: 1px solid $back-gray;
    }
  }

  @media $not-small-mobile {
    td + td,
    th + th {
      padding-left: 15px;
    }
  }

  .spacer td {
    padding-top: 5px;
    border-bottom-width: 2px;

    @media $small-mobile {
      display: none;
    }
  }

  tr:first-child th {
    padding-top: 0;
  }

  @media $small-mobile {
    tr th:not(:first-child) {
      display: none;
    }

    th {
      margin-bottom: 10px;
    }

    td {
      display: inline;
    }

    tr {
      display: block;
    }

    tr + tr {
      margin-top: 1em;
    }
  }

  th:not(.sorttable_nosort) {
    cursor: pointer;
  }

  #sorttable_sortfwdind,
  #sorttable_sortrevind {
    position: absolute;
    left: 0; bottom: 20px;
  }
}

.variants-table--param-item {
  display: inline-block;
  font-weight: normal;
  color: $white;
  background: $yellow;
  padding: 8px;
}

.variants-table--price {
  font-weight: bold;
  white-space: nowrap;
}

.variants-popup {
  .popup--caption {
    margin-bottom: 2em;
  }

  @media $mobile {
    padding-left: 30px;
  }

  @media $small-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.product-card--show-more-accessories {
  display: inline-block;
  position: relative;
  margin-top: 2em;
  margin-bottom: 1em;
  color: $blue;
  text-decoration: none;
  border-bottom: 1px solid rgba($blue, .5);
  font-weight: bold;
  transition: all .25s ease;
  cursor: pointer;

  &:hover {
    color: $blue;
    border-bottom-color: $blue;
  }

  &.is-hidden {
    display: none;
  }
}
