
/*   -=========== Basket pages ===========-   */

.empty-basket-page {
  padding-bottom: 100px;
}

.empty-basket-page--button {
  @extend .btn, .black-bordered-btn, .rounded-btn;
  padding: 0 30px;
  line-height: 40px;
  margin-top: 35px;
}


.order-page--header {
  margin-bottom: 35px;
  display: flex;
  align-items: center;

  @media $landscape-mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.order-page--caption {
  @extend .h1;
  color: $blue;
}

.order-steps {
  display: flex;
  align-items: center;
  margin-left: 5vw;

  @media $landscape-mobile {
    margin-left: 0;
    margin-top: 25px;
  }

  @media $small-mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .item {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    list-style: none;

    @media $small-mobile {
      font-size: 1rem;
    }

    &, a {
      color: $gray;
    }

    a {
      text-decoration: none;
    }
  }

  .item + .item {
    @media $not-small-mobile {
      margin-left: 35px;
    }

    @media $small-mobile {
      margin-top: 10px;
    }
  }

  .item--step {
    display: inline-block;
    width: 43px; height: @width;
    line-height: @height;
    text-align: center;
    border-radius: 50%;
    border: 2px solid $gray;
    margin-right: 15px;

    @media $small-mobile {
      width: 20px; height: @width;
      line-height: (@height + 2px);
      margin-right: 5px;
    }
  }

  .is-active {
    color: $blue;

    .item--step {
      border-color: $blue;
      background: $blue;
      color: $white;
    }
  }
}


.order-page--content {
  padding-bottom: 80px;
}


.order-table {
  font-size: 1.4rem;
  margin-left: -35px;
  margin-right: -15px;
  width: calc(100% + 50px);
  color: $gray;

  @media $small-mobile {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  th {
    background: $back-gray;
    padding: 15px 35px;
    white-space: nowrap;
    text-align: left;

    @media $handheld {
      padding-right: 20px;

      &:not(:first-child) {
        padding-left: 20px;
      }
    }

    @media $small-mobile {
      display: none;
    }
  }

  th:last-child {
    padding: 0;
  }

  .product {
    border-bottom: 2px solid $back-gray;

    td:not(:last-child) {
      padding: 40px 35px;

      @media $handheld {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @media $small-mobile {
      display: grid;
      grid-template-columns: 48px 1fr 41px;
      grid-template-rows: auto auto auto;
      align-items: center;
      padding: 25px 0;
    }
  }

  .product--image {
    padding-left: 35px;

    img {
      max-height: 135px;
      max-width: 80px;
    }

    @media $small-mobile {
      grid-column: 1 / 2;
      grid-row: 1 / 4;
      padding: 0 !important;

      img {
        max-width: 100%;
      }
    }
  }

  .product--description {
    padding-left: 35px;
    padding-right: 35px;

    @media $small-mobile {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      padding: 0 15px !important;
    }
  }

  .product--name {
    margin-bottom: 1em;

    &, a {
      color: $blue;
    }

    a {
      text-decoration: none;
    }

    @media $small-mobile {
      margin-bottom: 0;
    }
  }

  .product--category {
    text-transform: uppercase;
    font-weight: bold;

    @media $small-mobile {
      display: none;
    }
  }

  .product--params {
    .item {
      display: flex;
    }

    .item_article {
      font-size: 1.2rem;
      margin-bottom: 1em;
    }

    .item--label {
      margin-right: 10px;
    }

    @media $small-mobile {
      display: none;
    }
  }

  .product--price {
    font-weight: bold;

    @media $small-mobile {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      padding: 10px 15px !important;
    }
  }

  .product--controls {
    @media $small-mobile {
      padding: 0 15px !important;
    }
  }

  .product--remove-button {
    @extend .btn;
    width: 15px; height: @width;
    position: relative;
    transform: rotate(-45deg);
    font-size: 0;
    vertical-align: middle;

    &:before, &:after {
      content: '';
      display: block;
      background: $blue;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 17px; height: 2px;
    }

    &:after {
      width: 2px; height: 17px;
    }

    @media $small-mobile {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      font-size: 1.2rem;
      font-weight: bold;
      color: #b2b2b2;
      transform: none;

      &:before, &:after {
        display: none;
      }
    }
  }

  .product--amount {
    @media $small-mobile {
      grid-column: 3 / 4;
      grid-row: 1 / 4;
      padding: 0 !important;
    }
  }

  .spinner {
    @media $small-mobile {
      padding: 40px 0;

      .spinner-up {
        left: 50%; top: 0;
        transform: translateX(-50%);
      }

      .spinner-down {
        left: 50%; bottom: 0; top: auto;
        transform: translateX(-50%);
      }
    }
  }
}


.order-page--footer {
  padding-top: 20px;
  text-align: right;

  @media $small-mobile {
    text-align: center;
  }
}

.order-page--footer-price {
  font-size: 1.4rem;
  font-weight: bold;
  color: $blue;
  margin-bottom: 1.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media $small-mobile {
    justify-content: center;
  }
}

.order-page--footer-price-label {
  @media $small-mobile {
    color: $gray;
  }
}

.order-page--footer-price-value {
  margin-left: 20px;
  white-space: nowrap;
}

.order-page--footer-button {
  @extend .btn, .yellow-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 30px;
}


// Second step

.order-page--form-container {
  max-width: 380px;
}


// Third step

.order-page_success {
  padding-top: 65px;
  padding-bottom: 233px;
  background: url(/i/order-success-pattern.png) no-repeat 50% 100%;

  @media $small-mobile {
    padding-top: 15px;
  }
}

.order-success {
  font-size: 1.4rem;
  line-height: 1.5;

  &, a {
    color: $gray;
  }

  p + p {
    margin-top: 2em;
  }

  .order-success--phones a {
    display: inline-block;
    margin-top: 5px;
  }
}

.order-success--number {
  display: flex;
  align-items: center;
  color: $blue;
  line-height: 1.2;
}

.order-success--number-value {
  font-size: 3rem;
  font-weight: bold;
  margin-right: 30px;
}

a.order-success--button {
  @extend .btn, .yellow-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 30px;
  font-weight: bold;
  color: $white;
}
