
/*   -=========== Catalogue ===========-   */

.catalogue-page {
  main-grid();
  align-items: start;

  .breadcrumbs {
    grid-column: 3 / 10;
    margin-left: ($grid-gap / 2);

    @media $handheld {
      grid-column-start: 4;
    }

    @media $landscape-mobile {
      grid-column-start: 1;
    }
  }

  @media $landscape-mobile {
    padding-top: 20px;
  }

  @media $mobile {
    background: $back-gray;
  }
}

.catalogue-page--caption {
  grid-column: 3 / 10;
  @extend .h1;
  margin-left: ($grid-gap / 2);

  @media $less-than-wrapper {
    grid-column-end: -1;
  }

  @media $handheld {
    grid-column-start: 4;
  }

  @media $landscape-mobile {
    grid-column-start: 1;
  }

  @media $small-mobile {
    font-size: 1.6rem;
    margin-bottom: 1em;
    margin-left: 10px;
  }
}

.filter {
  grid-column: 1 / 3;

  @media $notebook {
    margin-right: -($grid-gap / 2);
    margin-left: -($grid-gap / 2);
  }

  @media $handheld {
    grid-column-end: 4;
  }
}

.catalogue-page--content {
  grid-column: 3 / -1;

  @media $handheld {
    grid-column-start: 4;
  }

  @media $small-handheld {
    grid-column-start: 1;
  }

  .list-view {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: $grid-gap;
  }
}

.catalogue-page--content-wrapper {
  grid-column: 1 / 9;
  padding-left: ($grid-gap / 2);

  @media $less-than-wrapper {
    grid-column-end: -1;
  }

  @media $handheld {
    padding-right: ($grid-gap / 2);
  }

  @media $small-handheld {
    @media $not-small-mobile {
      margin-left: -($grid-gap / 2);
      margin-right: -($grid-gap / 2);
      padding-left: (2 * $grid-gap);
      padding-right: (2 * $grid-gap);
      padding-top: 20px;
      background: $back-gray;
    }
  }

  @media $small-mobile {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media $mobile {
    display: flex;
  }
}

.catalogue-page--vitrine {
  grid-column: 1 / -1;
  margin-left: -($grid-gap / 2);
}

.catalogue-page--teaser {
  display: block;
  grid-column-start: 1;
  grid-column-end: -1;
  margin-left: -($grid-gap / 2);
  margin-right: -($grid-gap / 2);
  margin-top: -5px;
  margin-bottom: 30px;
  line-height: 0;

  @media $small-handheld {
    margin-bottom: 0;
  }

  @media $mobile {
    display: none;
  }
}

.catalogue-page--vitrine {
  background: $back-gray;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: -($grid-gap / 2);

  .vitrine {
    grid-template-columns: repeat(10, 1fr);

    @media $less-than-wrapper {
      grid-template-columns: repeat(8, 1fr);
    }

    @media $notebook {
      grid-template-columns: repeat(6, 1fr);
    }

    @media $handheld {
      grid-template-columns: repeat(4, 1fr);
    }

    @media $small-mobile {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: ($grid-gap / 2);
    }
  }
}

.search-page--vitrine {
  background: $back-gray;
  padding-top: 20px;
  padding-bottom: 20px;
}

.catalogue-page--text {
  line-height: 1.35;
  color: $gray;

  @media $small-handheld {
    display: none;
  }
}

.catalogue-page--tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 17px;

  @media $small-handheld {
    display: none;
  }

  .item {
    line-height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    bordered-btn(2px, $blue);
    font-size: 1.4rem;
    color: $gray;
    margin-right: 15px;
    text-decoration: none;
    margin-bottom: 13px;

    &:hover, &.is-active {
      background: $blue;
      color: $white;
    }
  }
}

.catalogue-page--sorting {
  padding-top: 20px;
  padding-bottom: 22px;
  display: flex;

  @media $not-small-handheld {
    justify-content: space-between;
  }

  @media $small-handheld {
    padding-top: 14px;
    padding-bottom: 5px;
  }

  @media $mobile {
    order: 1;
    padding: 0;
  }

  .items-group {
    display: flex;

    @media $small-handheld {
      + .items-group {
        margin-left: 20px;
      }
    }
  }

  .item + .item {
    margin-left: 40px;

    @media $small-handheld {
      margin-left: 20px;
    }
  }

  .item {
    display: flex;
    align-items: center;
  }

  .item--label {
    margin-right: 20px;
    font-size: 1.4rem;
    color: $gray;

    @media $handheld {
      display: none;
    }
  }

  .selectric {
    line-height: 40px;
    border-radius: 20px;
    background: $yellow;
    border: none;

    .label {
      color: $white;
      font-size: 1.4rem;
      margin-left: 18px;
      margin-right: 38px;
    }

    .button {
      width: 38px;

      &:before {
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        margin-left: -6px;
      }
    }
  }

  .selectric-items {
    border-color: $yellow;
    border-radius: 20px;
    overflow: hidden;

    li {
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 1.4rem;
    }

    li.highlighted {
      background: lighten($yellow, 5);
    }
  }

  .item--radio-group {
    display: flex;
  }

  .item--radio {
    box-sizing: border-box;
    width: 47px;
    line-height: 36px;
    border: 2px solid $yellow;
    color: $gray;
    font-size: 1.4rem;
    text-align: center;

    input {
      display: none;
    }

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &.is-checked {
      background: $yellow;
      color: $white;
    }

    &:not(.is-checked) {
      cursor: pointer;
    }
  }

  .item--radio + .item--radio {
    margin-left: -2px;
  }

  .catalogue-view-icon {
    display: inline-block;
    width: 55px; height: 36px;
    border: 2px solid $yellow;
    background-position: 50%;
    background-repeat: no-repeat;

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:not(.is-active) {
      &.tile-view {
        background-image: url(/i/view-icon-tile-gray.png);
      }

      &.line-view {
        background-image: url(/i/view-icon-line-gray.png);
      }
    }

    &.is-active {
      background-color: $yellow;

      &.tile-view {
        background-image: url(/i/view-icon-tile-white.png);
      }

      &.line-view {
        background-image: url(/i/view-icon-line-white.png);
      }
    }
  }

  .catalogue-view-icon + .catalogue-view-icon {
    margin-left: -2px;
  }
}

.catalogue-page--pager {
  text-align: center;
  min-height: 20px;
}

.list-view-loading {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    z-index: 100;
    background: rgba($white, .85);
  }

  &:after {
    content: '';
    display: block;
    width: width('i/ajax/process.gif'); height: height('i/ajax/process.gif');
    position: absolute;
    left: 50%; top: 200px;
    z-index: 100;
    transform: translateX(-50%);
    background: url(/i/ajax/process.gif) no-repeat 50%;
  }
}

.list-view-loading-bottom {
  &:after {
    top: auto; bottom: 200px;
  }
}


// Catalogue links page

.catalogue-links-page {
  background: #5271d4;
  padding-top: 25px;
  padding-bottom: 80px;
}

.catalogue-links-page--caption {
  @extend .h1;
  color: $white;
  margin-bottom: 35px;
}

.catalogue-links-group + .catalogue-links-group {
  margin-top: 55px;
}

.catalogue-links-group {
  display: flex;

  @media $mobile {
    flex-direction: column;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
    align-items: flex-start;
    padding-top: 15px;

    @media $tablet {
      padding-top: 5px;
    }

    @media $mobile {
      margin-left: 0;
    }
  }

  li {
    list-style: none;
    margin-right: 50px;
    margin-top: 15px;

    @media $handheld {
      margin-right: 30px;
    }
  }

  li a {
    color: $white;
    text-decoration: none;
    font-size: 1.6rem;
    border-bottom: 1px solid #7ccdfd;

    &:hover {
      color: #7ccdfd;
    }
  }
}

.catalogue-links-group--caption {
  font-size: 4.8rem;
  font-weight: bold;
  color: #7ccdfd;
  text-decoration: none;
  flex-shrink: 0;

  @media $tablet {
    font-size: 3.6rem;
  }

  @media $mobile {
    font-size: 2.2rem;
  }
}

a.catalogue-links-group--caption:hover {
  color: lighten($blue, 5);
}

.catalogue-links-page--text {
  color: $white;
  margin-bottom: 30px;
  line-height: 1.5;
}
