
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  position: absolute;
  background: $body-bg;
  z-index: 333;
  padding: 35px 60px 50px;
}

.popup--close {
  @extend .btn;
  width: 30px; height: 30px;
  position: absolute;
  top: 30px; right: 30px;
  transform: rotate(45deg);
  cursor: pointer;
  background: transparent;

  &:before, &:after {
    content: '';
    display: block;
    background: $blue;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 17px; height: 2px;
  }

  &:after {
    width: 2px; height: 17px;
  }
}

.popup--caption {
  color: $blue;
  font-weight: bold;
  margin-bottom: 1em;
}


// Quick view

.quick-view-popup {
  box-sizing: border-box;
  width: 1262px;
  max-width: 100%;
  padding: 50px 60px 70px 50px;
  color: $gray;
}

.quick-view-popup--layout {
  display: flex;
}

.quick-view-popup--gallery {
  width: 365px;
  margin-right: 15px;
}

.quick-view-popup--category {
  @extend .h1;
  margin-bottom: 5px;

  @media $small-mobile {
    font-size: 1.6rem;
  }
}

.quick-view-popup--name {
  @extend .h2;
  font-weight: normal;
  color: $blue;
  margin-bottom: 30px;

  @media $small-mobile {
    font-size: 1.4rem;
  }
}


// Callback

.callback-popup {
  width: 320px;
  max-width: 100%;

  @media $small-mobile {
    box-sizing: border-box;
  }
}


// Fast order

.fast-order-popup {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 280px;
  max-width: 100%;
}

.fast-order-popup--image {
  display: block;
  text-align: center;
  margin-bottom: 1em;

  img {
    max-height: 120px;
  }
}

.fast-order-popup--description {
  font-size: 1.4rem;
  margin-bottom: 1.5em;

  a {
    text-decoration: none;
  }

  &, a {
    color: $blue;
  }
}

.fast-order-popup--article {
  margin-top: .5em;

  &, a {
    color: $black;
  }
}


// Question

.question-popup--text {
  margin-bottom: 1.5em;
}
