
/*   -=========== Info ===========-   */

.info-page {
  padding-bottom: 50px;
}

.info-page--caption {
  @extend .h1;
  margin-bottom: 35px;
}
