
/*   -=========== News and articles ===========-   */

.news-page--caption {
  @extend .h1;
  margin-bottom: 55px;

  @media $mobile {
    margin-bottom: 20px;
  }
}

.news-list {
  margin-bottom: 90px;

  .item {
    display: flex;
    background: $back-gray;
    color: $gray;
  }

  .item--image {
    flex-shrink: 0;
    line-height: 0;
  }

  .item + .item {
    margin-top: 35px;

    @media $small-mobile {
      margin-top: 20px;
    }
  }

  .item--content {
    padding: 65px 70px 50px 55px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    @media $small-mobile {
      padding: 20px;
    }
  }

  .item--header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    @media $small-mobile {
      flex-direction: column;
    }
  }

  .item--text {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .item--date {
    font-size: 1.2rem;
    color: $blue;
    margin-top: .65em;
    white-space: nowrap;
    padding-left: 20px;

    @media $small-mobile {
      padding-left: 0;
    }
  }

  .item--caption {
    color: $blue;
    font-size: 2.4rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    @media $small-mobile {
      font-size: 1.6rem;
    }
  }

  .item--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item--more {
    @extend .btn, .yellow-btn, .rounded-btn;
    line-height: 40px;
    padding: 0 25px;
    font-weight: bold;
  }

  .item--info {
    font-size: 1.2rem;
    color: $blue;

    .icon + .icon {
      margin-left: 25px;
    }

    .icon {
      padding-left: 28px;
      line-height: 20px;
    }

    .icon_time {
      background: url(/i/time-icon.png) no-repeat 0 50%;
    }

    .icon_pages {
      background: url(/i/pages-icon.png) no-repeat 0 50%;
    }
  }

  .item--image_video {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 75px; height: @width;
      border-radius: 50%;
      background: rgba($blue, .85) url(/i/play-icon.png) no-repeat 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color .25s ease;
    }

    &:hover:after {
      background-color: $blue;
    }
  }

  .pager {
    text-align: center;
  }
}

.reviews-list {
  display: flex;
  flex-wrap: wrap;

  @media $not-small-mobile {
    margin-bottom: 180px;
  }

  .item {
    background: $back-gray;
    color: $gray;
    margin-bottom: 25px;
    flex-basis: calc(33.33% - 17px);

    @media $small-handheld {
      flex-basis: calc(50% - 10px);
    }

    @media $small-mobile {
      flex-basis: 100%;
    }
  }

  @media $not-small-mobile {
    @media $small-handheld {
      .item:not(:nth-child(2n + 1)) {
        margin-left: 20px;
      }
    }
  }

  @media $not-small-handheld {
    .item:not(:nth-child(3n + 1)) {
      margin-left: 25px;
    }
  }

  .item--content {
    padding: 25px 25px 23px 48px;

    @media $small-mobile {
      padding: 0 20px 20px 48px;
    }
  }

  .item--caption {
    display: inline-block;
    color: $blue;
    font-size: 2.4rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-decoration: none;

    @media $small-mobile {
      font-size: 1.6rem;
    }
  }

  .item--text {
    font-size: 1.2rem;
    line-height: 1.5;

    p + p {
      margin-top: 1em;
    }

    p {
      position: relative;
    }

    .icon-plus {
      display: block;
      width: 11px; height: 11px;
      position: absolute;
      top: 3px; left: -28px;

      &:before {
        content: '';
        display: block;
        width: 11px; height: 3px;
        background: $blue;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        display: block;
        width: 3px; height: 11px;
        background: $blue;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .icon-minus {
      display: block;
      width: 11px; height: 11px;
      position: absolute;
      top: 3px; left: -28px;

      &:before {
        content: '';
        display: block;
        width: 11px; height: 3px;
        background: $blue;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .icon-summary {
      display: block;
      width: 11px; height: 11px;
      position: absolute;
      top: 3px; left: -28px;

      &:before {
        content: '';
        display: block;
        width: 11px; height: 3px;
        background: $blue;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        margin-top: -3px;
      }

      &:after {
        content: '';
        display: block;
        width: 11px; height: 3px;
        background: $blue;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 3px;
      }
    }
  }

  .item--more {
    @extend .btn, .yellow-btn, .rounded-btn;
    line-height: 40px;
    padding: 0 25px;
    margin-top: 15px;
  }
}


// Details page

.news-item-page--caption {
  @extend .h1;
  margin-bottom: 20px;
}

.news-item--date {
  display: block;
  font-size: 1.2rem;
  color: $blue;
  margin-bottom: 20px;
}

.news-item--content,
.info-page--content {
  color: $gray;
  max-width: 917px;
  margin-bottom: 50px;

  a {
    color: $gray;
    border-bottom-color: rgba($gray, .3);

    &:hover {
      border-bottom-color: $gray;
    }
  }

  .img {
    background: $back-gray;
  }

  .img--description {
    padding: 30px 45px 40px;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .img--caption {
    font-size: 1.2rem;
    color: $blue;
    margin-bottom: .5em;
  }
}
