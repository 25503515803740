
/*   -=========== Vitrine ===========-   */

.vitrine-section--caption {
  @extend .h1;
  padding: 50px;

  a {
    color: $blue;
    text-decoration: none;
  }

  @media $less-than-wrapper {
    text-align: center;
  }

  @media $small-mobile {
    padding: 30px 20px;
  }
}

.vitrine {
  vitrine-grid();
  grid-row-gap: 30px;

  .product {
    box-sizing: border-box;
    grid-column-end: span 2;
    background: $white;
    padding: 25px 20px 45px;
    position: relative;
    width: 100%; // ff 52 hack
  }

  .product--image {
    position: relative;
    margin-top: 30px;

    &:before {
      content: '';
      display: block;
      padding-bottom: 125%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .product--controls {
    position: absolute;
    top: 40px; right: 22px;
    font-size: 0;

    html:not(.is-touch-device) & {
      opacity: 0;
      transition: opacity .25s ease;
    }

    .item {
      @extend .btn;
      vertical-align: middle;
      font-size: 0;
    }

    .item + .item {
      margin-left: 14px;
    }
  }

  .product:hover .product--controls {
    opacity: 1;
  }

  .product--quick-view-btn {
    width: width('i/details-icon.svg'); height: height('i/details-icon.svg');
    background: url(/i/details-icon.svg) no-repeat 50%;

    @media $handheld {
      display: none !important;
    }
  }

  .product--fav-btn {
    width: width('i/heart-icon-mini.svg'); height: height('i/heart-icon-mini.svg');
    background: url(/i/heart-icon-mini.svg) no-repeat 50%;

    &.active {
      background-image: url(/i/heart-icon-blue.svg);
    }
  }

  .product--compare-btn {
    width: width('i/compare-icon-gray.svg'); height: 20px;
    background: url(/i/compare-icon-gray.svg) no-repeat 50%;

    &.active {
      background-image: url(/i/compare-icon-blue.svg);
    }
  }

  .product--icons {
    product-icons-prototype();
    position: absolute;
    top: 30px; left: 18px;
    z-index: 10;
  }

  .product--name {
    font-size: 1.2rem;
    line-height: 1.35;
    padding-bottom: 68px;
    position: relative;

    a {
      text-decoration: none;
    }
  }

  &:not(.line-view) .product--article {
    font-size: 1.1rem;
    color: $light-gray;
    position: absolute;
    left: 0; bottom: 100%;
    margin-bottom: .25em;
  }

  .product--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 20px; bottom: 45px;
    width: calc(100% - 40px);
    min-height: 40px;
    color: $gray;
  }

  .product--btn-group {
    position: relative;
  }

  &:not(.line-view) .product-card--fast-order-link {
    position: absolute;
    left: 50%; top: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .product--buy-btn {
    buy-btn-prototype();
    font-size: 0;
  }

  .product-card--buy-btn_preorder {
    padding-left: 13px;
    padding-right: 42px;
    background-image: none;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: width('i/preorder-icon-white.png'); height: height('i/preorder-icon-white.png');
      background: url(/i/preorder-icon-white.png) no-repeat 50%;
      position: absolute;
      right: 12px; top: 50%;
      transform: translateY(-50%);
    }
  }

  .product--not-avail-placeholder {
    font-weight: bold;
    font-size: 1.4rem;
  }

  .product--price-group {
    position: relative;
    white-space: nowrap;
  }

  .product--price {
    font-size: 1.8rem;
    font-weight: bold;
    position: relative;
  }

  .product--old-price {
    color: $light-gray;
    font-size: 1.4rem;
    text-decoration: line-through;
    position: absolute;
    left: 0; top: -26px;
  }

  .product--last-price-label {
    color: $light-gray;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1;
    position: absolute;
    left: 0; bottom: 100%;
    margin-bottom: 5px;
  }
}


.vitrine_two-rows {
  @media $notebook {
    .product:nth-child(8) ~ .product {
      display: none;
    }
  }

  @media $tablet {
    .product:nth-child(6) ~ .product {
      display: none;
    }
  }

  @media $small-handheld {
    .product:nth-child(4) ~ .product {
      display: none;
    }
  }
}


.vitrine.line-view {
  @media $notebook {
    padding-left: $grid-gap;
    padding-right: $grid-gap;
  }

  @media $handheld {
    padding-left: $grid-gap;
    padding-right: $grid-gap;
  }

  .product {
    grid-column-end: span 8;
    display: flex;
    color: $gray;
    font-size: 1.4rem;
  }

  .product--image {
    width: 250px;
  }

  .product--description-column {
    margin-left: 35px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product--price-column {
    margin-left: 35px;
    display: flex;
  }

  .product--price-row {
    display: flex;
    align-items: center;
    margin-top: 50px;

    @media $handheld {
      flex-direction: column;
      margin-top: 85px;
    }
  }

  .product-card--buttons-group {
    @media $desktop {
      margin-left: 35px;
    }

    @media $handheld {
      margin-top: 20px;
    }
  }

  .product--controls {
    opacity: 1 !important;
  }

  .product--category {
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
  }

  .product--name {
    font-size: 1.8rem;
    margin-bottom: 1em;

    a {
      text-decoration: none;
      color: $blue;
    }
  }

  .product--article {
    font-weight: bold;
    margin-bottom: 1em;
  }

  .product--params {
    margin-bottom: 1em;

    dt, dd {
      display: inline;
      line-height: 1.5;
    }

    dd {
      margin-left: 5px;
    }

    dd:after {
      content: '\A';
      white-space: pre;
    }
  }

  .product--price {
    font-family: $second-font;
    font-size: 2.6rem;
    font-weight: bold;

    .currency {
      font-family: $master-font;
    }
  }

  .product--old-price {
    position: static;
    font-family: $second-font;
    font-size: 1.4rem;
    text-decoration: line-through;
    color: $light-gray;

    .currency {
      font-family: $master-font;
    }
  }

  .product--warranty-line {
    display: flex;
    align-items: center;
  }

  .product--warranty {
    font-size: 1.4rem;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    background: url(/i/warranty-icon.png) no-repeat 0 50%;

    @media $not-small-mobile {
      padding-left: 45px;
      margin-left: -7px;
    }

    @media $small-mobile {
      padding-left: 40px;

      br {
        display: none;
      }
    }
  }

  .product--rostest-icon {
    margin-left: 5vw;
  }

  .product--quick-view-btn {
    box-sizing: border-box;
    display: block;
    width: 43px; height: @width;
    border: 2px solid $blue;
    border-radius: 50%;
    background: url(/i/zoom-icon-blue.png) no-repeat 50%;
  }
}


.product-card .vitrine {
  grid-template-columns: repeat(10, 1fr);
  padding-left: 0;
  padding-right: 0;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media $tablet {
    grid-template-columns: repeat(6, 1fr);
  }

  @media $small-handheld {
    grid-template-columns: repeat(4, 1fr);

    @media $not-small-mobile {
      padding-left: 45px;
      padding-right: 45px;
    }

    @media $mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media $small-mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ($grid-gap / 2);
  }

  .product {
    box-shadow: 0 0 46px 3px rgba($black, .08);
  }
}


.vitrine_one-line {
  @media $not-small-mobile {
    @media $less-than-wrapper {
      .product:nth-child(5) ~ .product {
        display: none;
      }
    }

    @media $notebook {
      .product:nth-child(4) ~ .product {
        display: none;
      }
    }

    @media $tablet {
      .product:nth-child(3) ~ .product {
        display: none;
      }
    }

    @media $landscape-mobile {
      .product:nth-child(2) ~ .product {
        display: none;
      }
    }
  }

  @media $small-mobile {
    position: relative;

    .slick-list {
      grid-column: 1 / -1;
    }

    .slick-arrow {
      @extend .btn;
      width: 30px; height: 30px;
      background: transparent;
      font-size: 0;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      z-index: 100;

      &:before {
        content: '';
        display: block;
        width: 10px; height: @width;
        border-top: 2px solid $gray;
        position: absolute;
      }
    }

    .slick-disabled {
      opacity: .25;
    }

    .slick-prev {
      left: 25px;

      &:before {
        left: 50%;
        border-left: 2px solid $gray;
        transform: rotate(-45deg);
        transform-origin: left top;
        margin-left: -5px;
      }
    }

    .slick-next {
      right: 25px;

      &:before {
        right: 50%;
        border-right: 2px solid $gray;
        transform: rotate(45deg);
        transform-origin: right top;
        margin-right: -5px;
      }
    }
  }
}


.vitrine-spoiler + .vitrine-spoiler {
  margin-top: 10px;
}

.vitrine-spoiler--caption {
  color: $blue;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 10px; height: 10px;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    position: absolute;
    right: 100%;
    margin-right: 30px;

    .vitrine-spoiler.is-expanded & {
      transform: rotate(-45deg);

      @media $not-mobile {
        top: 1.1em;
      }
    }

    .vitrine-spoiler:not(.is-expanded) & {
      transform: rotate(135deg);
      top: .6em;
    }
  }

  @media $less-than-wrapper {
    @media $not-mobile {
      padding-left: 45px;

      &:before {
        right: auto; left: 0;
      }
    }
  }

  @media $mobile {
    font-size: 1.2rem;
    text-transform: lowercase;
    padding-left: 25px;

    &:before {
      left: 2px; top: 1.25em;
    }
  }
}

.vitrine-spoiler--body {
  overflow: hidden;
  transition: all .25s ease;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: -@padding-left;
  margin-right: -@padding-right;

  .vitrine-spoiler.is-expanded & {
    padding-top: 30px;
    padding-bottom: 30px;
    max-height: 10000px;
    opacity: 1;

    @media $mobile {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .vitrine-spoiler:not(.is-expanded) & {
    max-height: 0;
    opacity: 0;
  }
}


.additional-vitrine-section {
  main-grid();
  background: $back-gray;
  padding: 25px 0 65px;
}

.vitrine_additional {
  wrapper();
  vitrine-grid();
  grid-template-columns: repeat(10, 1fr);
}
