
/*   -=========== Buttons ===========-   */

.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all .25s ease;
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}

.black-bordered-btn {
  color: $black;
  bordered-btn(1px, $black);

  &:hover {
    color: $white;
    background: $black;
  }
}

.blue-bordered-btn {
  color: $gray;
  bordered-btn(2px, $blue);

  &:hover {
    color: $white;
    background: $blue;
  }
}

.black-btn {
  background-color: $black;
  color: $white;

  &:hover {
    color: $white;
    background-color: lighten($black, 10);
  }
}

.yellow-btn {
  background-color: $yellow;
  color: $white;

  &:hover {
    color: $white;
    background-color: lighten($yellow, 10);
  }
}

.blue-btn {
  background-color: $blue;
  color: $white;

  &:hover {
    color: $white;
    background-color: lighten($blue, 10);
  }
}

.red-btn {
  background-color: $red;
  color: $white;

  &:hover {
    color: $white;
    background-color: lighten($red, 10);
  }
}

.white-bordered-btn {
  color: $white;
  bordered-btn(2px, $white);

  &:hover {
    color: $white;
  }
}

.rounded-btn {
  border-radius: 20px;
}


// Sizes

.wide-btn {
  width: 100%;
}

.ias-trigger-prev {

}
