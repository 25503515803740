
/*   -=========== Common components ===========-   */

buy-btn-prototype() {
  @extend .btn, .yellow-btn, .rounded-btn;
  font-family: $second-font;
  font-size: 1.4rem;
  font-weight: bold;
  width: 77px; height: 40px;
  line-height: @height;
  /* background-image: url(/i/cart-icon-white.png); */
  background-image: url(/i/cart-icon-white.svg);
  background-size: width('i/cart-icon-white.png') auto;
  background-repeat: no-repeat;
  background-position: 50%;
}

product-icons-prototype() {
  .icon {
    display: block;
    width: 43px; height: @width;
    line-height: @height;
    border-radius: 50%;
    color: $white;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .icon + .icon {
    margin-top: 10px;
  }

  .icon_new {
    background: $light-blue;
  }

  .icon_leader {
    background: $yellow;
  }

  .icon_discount {
    background: $red;
  }
}
