
/*   -=========== Layout ===========-   */

// Floats

.fl {
  float: left;
}

.fr {
  float: right;
}

.no-fl {
  float: none;
}

@media $not-small-mobile {
  .l-main {
    float: left;
    width: 49%;
  }

  .r-main {
    float: right;
    width: 49%;
  }
}

@media $small-mobile {
  .l-main + .r-main {
    margin-top: 10px;
  }
}


// Containers

.group, .nofloat {
  clearfix();
}

.no-overflow {
  overflow: hidden;
}

.flex-group {
  display: flex;

  &.flex-group_justify {
    justify-content: space-between;
  }
}

/* .wrapper {
  box-sizing: border-box;

  @media only screen and (min-width: $wrapper-width + 20px) {
    max-width: $wrapper-width;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: $wrapper-width + 19px) {
    @media $not-mobile {
      margin-left: 20px;
      margin-right: 20px;
    }

    @media $mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
} */

main-grid() {
  display: grid;
  grid-column-gap: $grid-gap;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: '. . wrapper wrapper wrapper wrapper wrapper wrapper wrapper wrapper . .';
  padding-left: ($grid-gap / 2);
  padding-right: ($grid-gap / 2);

  @media $less-than-wrapper {
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas: 'wrapper wrapper wrapper wrapper wrapper wrapper wrapper wrapper wrapper wrapper';
  }
}

.main-grid {
  main-grid();
}

vitrine-grid() {
  main-grid();
  grid-template-areas: 'none' !important;

  @media $notebook {
    grid-template-columns: repeat(8, 1fr);
    /* grid-template-areas: 'wrapper wrapper wrapper wrapper wrapper wrapper wrapper wrapper'; */
    padding-left: (1.5 * $grid-gap);
    padding-right: (1.5 * $grid-gap);
  }

  @media $tablet {
    grid-template-columns: repeat(6, 1fr);
    padding-left: (1.5 * $grid-gap);
    padding-right: (1.5 * $grid-gap);
  }

  @media $landscape-mobile {
    grid-template-columns: repeat(4, 1fr);
    padding-left: (2 * $grid-gap);
    padding-right: (2 * $grid-gap);
  }

  @media $mobile {
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
    grid-column-gap: ($grid-gap / 2);
  }

  @media $small-mobile {
    grid-template-columns: repeat(2, 1fr);
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
  }
}

grid-content-section() {
  grid-column: 3 / 11;
}

wrapper() {
  grid-area: wrapper;
  margin-left: -($grid-gap / 2);
  margin-right: -($grid-gap / 2);

  @media $less-than-wrapper {
    margin-left: $grid-gap;
    margin-right: $grid-gap;
  }

  @media $small-mobile {
    margin-left: ($grid-gap / 2);
    margin-right: ($grid-gap / 2);
  }
}

.wrapper {
  wrapper();

  @media $small-mobile {
    margin-left: 0;
    margin-right: 0;
  }

  @media $not-small-mobile {
    margin-left: ($grid-gap / 2) !important;
    margin-right: ($grid-gap / 2) !important;
  }
}


// Margins

.m3 {
  margin-bottom: 3px;
}

.m7 {
  margin-bottom: 7px;
}

for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}


// Position

.relative {
  position: relative;
}

.static {
  position: static !important;
}


// Display

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}


// Devices display

@media $handheld {
  .only-desktop {
    display: none !important;
  }
}

@media $not-mobile {
  .only-mobile {
    display: none !important;
  }
}

@media $not-small-mobile {
  .only-small-mobile {
    display: none !important;
  }
}

@media $not-small-handheld {
  .only-small-handheld {
    display: none !important;
  }
}

@media $not-landscape-mobile {
  .only-landscape-mobile {
    display: none !important;
  }
}


@media $desktop {
  .not-desktop { // mobile + tablet
    display: none !important;
  }
}

@media $mobile {
  .not-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-mobile {
  .not-small-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-handheld {
  .not-small-handheld { // desktop + tablet
    display: none !important;
  }
}

@media $landscape-mobile {
  .not-landscape-mobile {
    display: none !important;
  }
}
