
/*   -=========== Errors ===========-   */

.error-page {
  background: url(/i/404-bg.png) no-repeat 50% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media $landscape-mobile {
    @media $not-mobile {
      background: none;
    }
  }

  &.error-404 {

  }

  .error-content {
    max-width: calc(100% - 40px);
  }

  .error-text {

  }

  .error-code {
    width: calc(100vw - 40px); height: height('i/404.png');
    max-height: 30vh;
    background: url(/i/404.png) no-repeat 50% / contain;
    font-size: 0;
    margin-bottom: 60px;

    @media $small-mobile {
      margin-bottom: 30px;
      max-height: 20vh;
    }
  }

  .error-note {
    text-align: center;
    color: $blue;
    font-size: 1.8rem;
  }

  .error-button {
    @extend .btn, .red-btn, .rounded-btn;
    line-height: 40px;
    padding: 0 30px;
    margin-top: 25px;
  }

  .error-logo {
    display: block;
    width: width('i/mobil-market-logo.png'); height: height('i/mobil-market-logo.png');
    background: url(/i/mobil-market-logo.png) no-repeat 50%;
    position: absolute;
    left: 5vw; top: 5vw;
  }
}
