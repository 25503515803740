
/*   -=========== Override vendor styles ===========-   */

.ui-autocomplete {
  z-index: 1010;
  max-height: 410px;
  overflow: auto;
  background: $white;
  list-style: none;
  word-wrap: break-word;
  box-shadow: 0 12px 13px rgba(#393e50, .13);
}

.ui-menu {
  .ui-menu-item {
    padding: 0;

    > a {
      display: block;
      font-size: 1.4rem;
      color: $blue;
      line-height: 1.2;
      word-break: break-word;
      cursor: pointer;
    }

    &:hover > a {
      color: $black;
    }
  }
}

.autocomplete-item {
  padding: 10px;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    max-width: 100%;
  }
}

.autocomplete-item--pic {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  margin-right: 10px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ui-helper-hidden-accessible {
  display: none;
}


// Selectric

.select-container {
  display: inline-block;
}

.selectric {
  border: 1px solid #BDBDBD;
  background: $white;
  color: #000;
  height: 41px;
  line-height: @height - 3px;
  text-align: left;

  .label {
    font-size: 2rem;
    margin-left: 25px;
    margin-right: 47px;
  }

  .button {
    width: 47px; height: 100%;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 8px; height: 8px;
      border-left: 3px solid #000;
      border-bottom: 3px solid #000;
      border-radius: 1px;
      position: absolute;
      left: 50%; top: 50%;
      transform: rotate(-45deg) translate(50%, -50%);
      margin-left: -3px;
    }
  }
}

.selectric-items {
  text-align: left;

  li {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1.6rem;
  }

  .disabled {
    display: none;
  }
}


// alertify

.alertify {
  border: 2px solid $blue;
  width: 250px;
  margin-left: 0;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
}

.alertify-message {
  margin-bottom: 15px;
}

.alertify-button {
  display: inline-block;
  margin-left: 0 !important;
  line-height: 30px !important;
  width: 40px;
}

.alertify-button-ok {
  background: $blue;
  color: $white;
  box-shadow: 0 0 0 2px $blue;

  &:hover {
    background: lighten($blue, 10);
    box-shadow: 0 0 0 2px lighten($blue, 10);
  }
}

.alertify-button-cancel {
  background: $white;
  box-shadow: 0 0 0 2px $blue;
  margin-left: 10px !important;

  &:hover {
    background: $blue;
    color: $white;
  }
}
