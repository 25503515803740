
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Breadcrumbs

.breadcrumbs {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 1.2rem;

  /* @media $small-handheld {
    overflow: hidden;
    height: 0;
    margin-top: 0;
    margin-bottom: 1em;
  } */

  @media $mobile {
    display: none;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  li + li {
    margin-left: 30px;

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 8px; height: 8px;
      border-top: 1px solid $gray;
      border-right: 1px solid $gray;
      border-radius: 1px;
      position: absolute;
      right: 100%; top: 50%;
      transform: rotate(45deg) translateY(-50%);
      margin-right: 16px;
      margin-top: -1px;
    }
  }

  li > span {
    color: $black;
  }

  a {
    text-decoration: none;
    color: $gray;

    &:hover {
      color: $blue;
    }
  }
}

.breadcrumbs--back {
  position: relative;
  padding: 9px 40px 11px;
  margin-top: -9px;
  margin-bottom: -11px;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%; height: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 30px;
    position: absolute;
    left: 0; top: 0;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 8px; height: 8px;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    border-radius: 1px;
    position: absolute;
    left: 20px; top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    margin-right: 24px;
    margin-top: -1px;
  }
}


// Pager

.pager {
  padding-top: 35px;
  padding-bottom: 35px;

  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
  }

  li + li {
    margin-left: 10px;

    @media $small-mobile {
      margin-left: 5px;
    }
  }

  a {
    display: block;
    width: 30px; height: @width;
    line-height: @height;
    font-size: 1.4rem;
    text-decoration: none;
    text-align: center;
    transition: all .25s ease;
  }

  li.active a {
    color: $yellow;
    font-weight: bold;
  }

  li:not(.active) a {
    color: $gray;

    &:hover {
      color: $yellow;
    }
  }

  .previous a,
  .next a {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 8px; height: @width;
      border-top: 2px solid $blue;
      position: absolute;
      top: 50%;
    }
  }

  .previous a:before {
    border-left: 2px solid $blue;
    transform: rotate(-45deg);
    transform-origin: left top;
    left: 50%;
  }

  .next a:before {
    border-right: 2px solid $blue;
    transform: rotate(45deg);
    transform-origin: right top;
    right: 50%;
  }
}

.reviews-pager {
  flex-basis: 100%;
  text-align: center;
}


// Catalogue

.catalogue-menu {
  @extend .menu;
  background: $blue;
  margin-left: -15px;
  margin-right: -15px;

  @media only screen and (max-width: 1023px) {
    margin-left: 0;
  }

  @media $portrait-tablet {
    display: none;
  }

  @media $landscape-mobile {
    display: none;
  }

  &, a {
    color: $white;
    transition: opacity .25s ease;
  }

  ul {
    width: 100%;
    padding-top: 10px;
    font-size: 1.6rem;

    /* @media $less-than-wrapper {
      margin-left: (1.5 * $grid-gap);
    } */
  }

  > ul > li > a,
  > ul > li > span {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    /* text-transform: lowercase; */
  }

  ul + ul {
    margin-top: 25px;
  }

  a,
  span {
    display: block;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .has-dropdown {
    > a {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 4px; height: @width;
        position: absolute;
        right: 5px; top: 50%;
        border-top: 2px solid $light-gray;
        border-right: 2px solid $light-gray;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    &.is-hovered > a:after {
      border-color: $white;
    }
  }

  .dropdown {
    width: auto;
    min-width: 160px;
    box-sizing: border-box;
    position: absolute;
    left: 100%; top: -80px;
    padding-top: -(@top - 10px);
    min-height: calc(100% + 80px);
    background: #69a8fc;
    white-space: nowrap;
    display: none;

    a {
      padding-left: 20px;
      padding-right: 20px;
    }

    .has-dropdown > a {
      padding-right: 20px;
    }

    li {
      min-width: 200px;

      span, a {
        padding-top: 11px;
        padding-bottom: 12px;
        line-height: .9;
        /* text-transform: lowercase; */
      }
    }

    .is-hovered > a {
      background: #83b8ff;
    }

    .dropdown {
      top: 0;
      min-height: 100%;
      background: #83b8ff;
    }
  }

  ul:not(.catalogue-menu--actions) .is-hovered {
    background: #69a8fc;
  }

  .is-hovered > .dropdown {
    display: block;
  }

  /* .dropdown .is-hovered > .dropdown {
    display: grid;
    grid-template-rows: repeat(14, 37px);
    grid-auto-flow: column;
    grid-column-gap: 15px;
  } */

  .dropdown .dropdown .is-hovered {
    opacity: .7;
  }
}

.header .catalogue-menu {
  display: none;
  transition: all .5s ease;
  width: 100%;
  padding-left: ($grid-gap / 2);
  position: absolute;
  z-index: 100;
  left: 0; top: 100%;
  height: 598px;

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
  }

  a {
    color: $white;
  }

  .menu-is-expanded & {
    display: block;
    opacity: 1;
  }

  > ul {
    > li:nth-child(12) ~ li {
      display: none;
    }
  }

  &.is-fully-expanded {
    > ul {
      > li:nth-child(12) ~ li {
        display: block;
      }
    }
  }
}

.catalogue-menu--more {
  cursor: pointer;

  span {
    display: block;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    background: #69a8fc;
  }

  .catalogue-menu:not(.is-fully-expanded) & {
    .expanded-text {
      display: none;
    }
  }

  .catalogue-menu.is-fully-expanded & {
    background: #69a8fc;

    .collapsed-text {
      display: none;
    }
  }
}

.catalogue-menu--actions {
  display: flex;
  justify-content: center;

  .item {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;

    a {
      /* width: 200px;
      max-width: 100%; */
      width: auto;
      padding: 0;
      line-height: 35px;
      position: relative;
      text-transform: uppercase;
      font-size: 1.1rem;
      padding-right: 40px;

      @media $notebook {
        font-size: 1rem;
      }

      &:after {
        display: block;
        width: 35px; height: @width;
        line-height: @height;
        text-align: center;
        border-radius: 50%;
        font-family: $second-font;
        position: absolute;
        right: 0; top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .item + .item {
    /* margin-top: 15px; */
    margin-left: 20px;
  }

  .item_newelties a:after {
    content: 'NEW';
    background: $light-blue;
    font-size: 1.2rem;
  }

  .item_discount a:after {
    content: '%';
    background: $red;
    font-size: 2rem;
  }
}


// Profile menu

.profile-menu {
  @extend .menu;
  width: 200px;

  li + li {
    border-top: 2px solid $back-gray;
  }

  a {
    display: block;
    padding: 15px 25px 15px 0;
    color: $gray;
    font-size: 1.4rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 10px; height: 10px;
      border-top: 2px solid $gray;
      border-right: 2px solid $gray;
      transform: rotate(45deg) translateY(-50%);
      position: absolute;
      right: 7px; top: 50%;
      transition: border-color .25s ease;
    }

    &:hover:after {
      border-color: $blue;
    }
  }

  li.active a {
    color: $blue;

    &:after {
      border-color: $blue;
    }
  }
}


// Mobile menu

.mobile-menu {
  @extend .menu;
  box-sizing: border-box;
  display: none;
  position: fixed;
  left: 0; top: 0;
  z-index: 1000;
  width: 100%; height: 100%;
  padding-top: 65px;
  overflow: auto;
  background: $blue;
  font-size: 1.6rem;

  a,
  span {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 20px;
    color: $white;
    text-decoration: none;
  }

  .mobile-menu-is-opened & {
    display: block;
  }
}

.mobile-menu--close {
  @extend .btn;
  width: 40px; height: 40px;
  background: none;
  position: absolute;
  top: 20px; right: 35px;
  transform: rotate(45deg);

  &:before, &:after {
    content: '';
    display: block;
    background: $white;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 20px; height: 3px;
  }

  &:after {
    width: 3px; height: 20px;
  }
}

.mobile-menu--search {
  margin-left: 35px;
  margin-right: 35px;
  position: relative;
  margin-bottom: 5px;

  .inp {
    border-bottom: 1px solid $white;
    color: $white;
    font-size: 1.6rem;
    padding-left: 48px;
    padding-top: .55em;
    padding-bottom: .55em;

    &::placeholder {
      color: $white;
    }
  }

  .btn {
    @extend .btn;
    width: 25px; height: 40px;
    background: url(/i/search-icon-white.svg) no-repeat 50%;
    background-size: width('i/search-icon-white.png') auto;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    font-size: 0;
  }
}

.mobile-menu--catalogue {
  margin-bottom: 25px;

  > ul > li > a,
  > ul > li > span {
    padding-left: 82px;
    text-transform: lowercase;
  }

  .dropdown {
    box-sizing: border-box;
    width: 100vw; height: 100vh;
    position: fixed;
    left: 100%; top: 0;
    z-index: 10;
    background: #69a8fc;
    transition: all .25s ease;
    padding-top: 65px;
    padding-bottom: 30px;
    overflow: auto;

    a,
    span {
      padding-left: 60px;
      padding-right: 30px;
    }
  }

  .is-hovered > .dropdown {
    left: 0;
  }

  .dropdown--back-link {
    display: block;
    width: 100%; height: 65px;
    position: absolute;
    top: 0; left: 0;

    &:before, &:after {
      content: '';
      display: block;
      width: 3px; height: 12px;
      position: absolute;
      left: 60px;
      background: $white;
    }

    &:before {
      bottom: 50%;
      transform: rotate(45deg);
      transform-origin: left bottom;
      margin-bottom: -5px;
    }

    &:after {
      top: 50%;
      transform: rotate(-45deg);
      transform-origin: left top;
      margin-top: 5px;
    }
  }
}

.mobile-menu--actions {
  margin-left: 40px;
  margin-right: 10px;
  margin-bottom: 20px;

  a {
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 42px;
    position: relative;
  }

  .icon {
    width: 28px; height: @width;
    line-height: @height;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    font-style: normal;
  }

  .icon_newelties {
    background: #69a8fc;
    font-size: .7rem;
  }

  .icon_discount {
    background: #c8442b;
    font-size: 1.3rem;
  }
}

.mobile-menu--controls {
  margin-left: 40px;
  margin-right: 10px;
  margin-bottom: 20px;

  a {
    text-transform: lowercase;
    padding-left: 42px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 28px; height: 28px;
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%);
    }
  }

  .item {
    position: relative;
  }

  .item_profile a:before {
    background: url(/i/profile-icon-white.svg) no-repeat 50%;
    background-size: width('i/profile-icon-m.png') auto;
  }

  .item_favorite a:before {
    background: url(/i/heart-icon-white.svg) no-repeat 50%;
    background-size: width('i/favorite-icon-m.png') auto;
  }

  .item_compare a:before {
    background: url(/i/compare-icon-m.png) no-repeat 50%;
  }

  .item_seen a:before {
    background: url(/i/seen-icon-white.svg) no-repeat 50%;
    background-size: width('i/seen-icon-m.png') auto;
  }

  .item--counter {
    position: absolute;
    left: 27px; top: -6px;
    font-size: 1.1rem;
  }
}

.mobile-menu--info {
  background: $yellow;
  padding-top: 15px;
  padding-bottom: 35px;

  a {
    text-transform: lowercase;
    padding-left: 82px;
  }
}
