
/*   -=========== Structure ===========-   */

// Base

body {
  width: 100%;
  max-width: 1920px;

  &.mobile-filter-is-opened,
  &.mobile-menu-is-opened {
    height: 100%;
    overflow: hidden;
  }
}

#structure {
  margin: auto;
  position: relative;

  @media $mobile {
    margin-top: $mobile-header-height;
  }
}

/* #content {
  @media $mobile {
    padding-top: 54px;
  }
} */


// Pre header

.pre-header {
  display: none;
  background: $blue;
  color: $white;
}

.pre-header--content {
  grid-content-section();
  margin-left: ($grid-gap / 2);
  margin-right: -($grid-gap / 2);
  padding-top: ($grid-gap / 2);
  padding-bottom: ($grid-gap / 2);
  font-size: 1.6rem;
  line-height: 1.5;
  padding-right: 40px;
  position: relative;

  @media $less-than-wrapper {
    grid-column: 1 / 11;
    margin-left: $grid-gap;
    margin-right: ($grid-gap / 2);
  }

  @media $landscape-mobile {
    margin-left: ($grid-gap / 2);
  }
}

.pre-header--close {
  @extend .btn;
  width: 25px; height: 25px;
  border-radius: 50%;
  background: $white;
  position: absolute;
  right: 0; top: 50%;
  transform: translateY(-50%) rotate(45deg);

  &:before, &:after {
    content: '';
    display: block;
    background: $blue;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 12px; height: 2px;
  }

  &:after {
    width: 2px; height: 12px;
  }
}


// Header

.header,
.pre-header--layout {
  main-grid();
}

.header {
  position: relative;
  z-index: 100;

  &, a {
    color: $gray;
  }

  /* @media $small-mobile {
    border-bottom: 1px solid $gray;
  } */

  @media $mobile {
    box-sizing: border-box;
    position: fixed;
    left: 0; top: 0;
    width: 100%;
    z-index: 1000;
    background: $white;
  }
}

.header--logo-container {
  grid-column: 1 / 3;
  grid-row: 1;
  place-self: center;
  padding-top: 10px;
  padding-bottom: 12px;

  @media $small-notebook {
    grid-column: 1 / 4;
  }

  @media $tablet {
    grid-column: 1 / 3;
  }

  @media $landscape-mobile {
    place-self: self-start;
    margin-left: ($grid-gap / 2);
  }

  @media $small-mobile {
    margin-left: 10px;
  }
}

.header--logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/mobil-market-logo.png'); height: height('i/mobil-market-logo.png');
  background: url(/i/mobil-market-logo.svg) no-repeat 50% / contain;
  font-size: 0;

  @media $mobile {
    width: 69px; height: 32px;
  }
}

.header--row {
  grid-content-section();
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ($grid-gap / 2);
  margin-right: -($grid-gap / 2);

  @media $less-than-wrapper {
    grid-column: 3 / 11;
    margin-right: ($grid-gap / 2);
  }

  @media $small-notebook {
    grid-column: 4 / 11;
  }

  @media $landscape-tablet {
    grid-column: 4 / 11;
  }

  @media $portrait-tablet {
    grid-column: 3 / 11;
    margin-right: -($grid-gap / 2);
  }

  @media $landscape-mobile {
    margin-left: ($grid-gap / 2);
    margin-right: ($grid-gap / 2);
  }

  @media $small-mobile {
    margin-right: 0;
    display: block;
  }
}

.header--row_first {
  padding-top: 10px;

  @media $not-landscape-mobile {
    border-bottom: 1px solid $gray;
  }

  @media $small-mobile {
    padding-top: 5px;
  }
}

.header--row_second {
  padding-top: 20px;
  padding-bottom: 20px;

  @media $small-notebook {
    align-items: flex-start;
  }

  @media $portrait-tablet {
    grid-column-start: 1;
    padding-top: 10px;
    padding-bottom: 0;
  }

  @media $landscape-mobile {
    grid-column-start: 1;
    padding-top: 10px;
    padding-bottom: 0;
  }

  @media $mobile {
    display: none;
  }
}

.header--catalogue-link-container {
  grid-column: 1 / 3;
  grid-row: 2;
  margin-left: -($grid-gap / 2);
  margin-right: -($grid-gap / 2);
  display: flex;
  align-items: center;
  position: relative;

  @media $bigger-than-wrapper {
    justify-content: center;
  }

  @media $less-than-wrapper {
    padding-left: (1.5 * $grid-gap);
  }

  @media $small-notebook {
    grid-column: 1 / 4;
  }

  @media $landscape-tablet {
    grid-column: 1 / 4;
  }

  @media $portrait-tablet {
    grid-column: 1 / 3;
  }

  @media $portrait-tablet {
    display: none;
  }

  @media $landscape-mobile {
    display: none;
  }

  .menu-is-expanded & {
    background: $blue;
  }
}

.header--left,
.header--right {
  display: flex;
  align-items: center;
}

@media $small-mobile {
  .header--left {
    display: none;
  }
}

.header--right {
  > * + * {
    margin-left: 25px;

    @media $landscape-mobile {
      margin-left: 40px;
    }

    @media $tablet {
      margin-left: 30px;
    }

    @media $small-mobile {
      margin-left: 0;
    }
  }

  @media $small-mobile {
    justify-content: flex-end;
  }
}

.header--phones {
  display: flex;
  align-items: center;
  position: relative;

  @media $small-notebook {
    flex-wrap: wrap;
    flex: .5;
  }

  @media $handheld {
    flex-wrap: wrap;
    flex: .5;
  }

  @media $landscape-mobile {
    display: none;
  }

  .item {
    font-size: 2.4rem;
    font-weight: bold;
    white-space: nowrap;
    position: relative;

    @media $small-notebook {
      font-size: 1.8rem;
    }

    @media $handheld {
      font-size: 1.6rem;
    }
  }

  .item--description {
    position: absolute;
    left: 0; top: 100%;
    font-size: 1.1rem;
    font-weight: normal;

    @media $small-notebook {
      display: none;
    }

    @media $handheld {
      display: none;
    }
  }

  @media $not-small-notebook {
    .item + .item {
      margin-left: 20px;
    }
  }
}

.header--callback-button {
  @extend .btn;
  padding-left: 22px;
  flex-shrink: 0;
  position: absolute;
  left: -4px; bottom: 100%;
  margin-bottom: 7px;
  font-size: 1.3rem;

  @media $small-notebook {
    margin-bottom: 5px;
  }

  span {
    display: inline-block;
    border-bottom: 1px solid rgba($gray, .5);
    transition: border-bottom-color .25s ease;
    white-space: nowrap;
  }

  &:before {
    content: '';
    display: block;
    width: 17px; height: 18px;
    background: url(/i/callback-icon.svg) no-repeat 50% / contain;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: $blue;

    &:before {
      background-image: url(/i/callback-icon-blue.svg);
    }

    span {
      border-bottom-color: $blue;
    }
  }

  @media $small-handheld {
    display: none;
  }
}

.header--mobile-callback {
  background: url(/i/callback-icon.svg) no-repeat 0 50%;
  padding-left: width('i/callback-icon.png'); height: height('i/callback-icon.png');
  line-height: @height;
  position: relative;
  flex-shrink: 1;

  @media $not-small-handheld {
    display: none;
  }
}

.header--worktime {
  margin-left: 10%;
  font-size: 1.4rem;
  white-space: nowrap;
  font-family: $second-font;
  color: $light-gray;

  @media $notebook {
    margin-left: 5%;
    flex: 1;
  }

  @media $handheld {
    flex: 1;
    line-height: 1.35;

    .divider {
      display: block;
      font-size: 0;
    }
  }

  @media $landscape-mobile {
    display: none;
  }
}

.header--profile,
.header--compare,
.header--favorites,
.header--seen,
.header--basket,
.header--mobile-callback {
  @extend .btn;
  vertical-align: middle;
  flex-shrink: 0;
}

.header--profile {
  background: url(/i/profile-icon.svg) no-repeat 0 50%;
  padding-left: width('i/profile-icon.png'); height: height('i/profile-icon.png');
  line-height: @height;
  position: relative;
  flex-shrink: 1;

  @media $small-mobile {
    margin-left: 20px;
  }
}

.header--profile-link {
  @extend .btn;
  background: transparent;
  width: 100%; height: height('i/profile-icon.png');
  position: absolute;
  left: 0; top: 0;
  z-index: 20;

  .is-active & {
    width: width('i/profile-icon.svg');
  }

  @media $mobile {
    display: none;
  }
}

.header--profile_logged-in {
  text-align: left;
  background-image: url(/i/profile-icon-blue.svg);
  background-size: width('i/profile-icon.png') auto;

  @media $not-mobile {
    padding-left: calc(width('i/profile-icon.png') + 7px);
  }

  > span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    line-height: 1.2;
    color: $gray;

    @media $mobile {
      display: none;
    }
  }
}

.header--profile-menu {
  @extend .menu;
  display: none;
  position: absolute;
  left: -10px; top: -21px;
  z-index: 10;
  padding: 16px 17px 16px 10px;
  background: $white;
  box-shadow: 0 12px 13px rgba(#393e50, .13);

  @media $less-than-wrapper {
    left: auto; right: -10px;
  }

  .is-active & {
    display: block;
  }

  ul {
    padding-top: 10px;
    font-size: 1.3rem;

    a:hover {
      color: $blue;
    }
  }
}

.header--profile-menu-top {
  padding-left: calc(width('i/profile-icon.png') + 10px);
  background: url(/i/profile-icon.svg) no-repeat 0 50%;
  background-size: width('i/profile-icon.png') auto;
  display: flex;
  align-items: center;
  line-height: 40px;

  .header--profile_logged-in & {
    background-image: url(/i/profile-icon-blue.svg);
  }
}

.header .header--login-btn {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 20px;
  font-size: 1.3rem;
  font-weight: bold;
}

.header--register-link {
  text-decoration: none;
  font-size: 1.3rem;
  color: $gray;
  margin-left: 10px;
}

.header--profile-menu-close {
  @extend .btn;
  background: transparent;
  width: 20px; height: 20px;
  margin-left: 15px;
  position: relative;
  transform: rotate(45deg);
  margin-top: 2px;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    background: $blue;
  }

  &:before {
    width: 13px; height: 2px;
  }

  &:after {
    width: 2px; height: 13px;
  }
}

.header--compare {
  width: width('i/compare-icon-gray.png'); height: height('i/compare-icon-gray.png');
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    background: url(/i/compare-icon-gray.svg) no-repeat 50%;
  }

  &:not(.is-filled) {
    opacity: .5;
  }

  &.is-filled:hover:before {
    background-image: url(/i/compare-icon-blue.svg);
  }

  @media $small-mobile {
    display: none;
  }
}

.header--favorites {
  width: width('i/heart-icon.png'); height: height('i/heart-icon.png');
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    background: url(/i/heart-icon.svg) no-repeat 50%;
  }

  &:not(.is-filled) {
    opacity: .5;
  }

  &.is-filled:hover:before {
    background-image: url(/i/heart-icon-blue.svg);
  }

  @media $small-mobile {
    display: none;
  }
}

.header--seen {
  width: width('i/seen-icon.png'); height: height('i/seen-icon.png');

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    background: url(/i/seen-icon.svg) no-repeat 50%;
    /* background-size: width('i/seen-icon.png') auto; */
  }

  &:not(.is-filled) {
    opacity: .5;
  }

  @media $not-notebook {
    position: relative;
  }

  @media $small-mobile {
    display: none;
  }

  &:hover .header--seen-content {
    display: block;
  }
}

.header--seen-content {
  display: none;
  position: absolute;
  left: -15px; top: -25px;
  z-index: 10;
  padding: 25px 17px 25px 10px;
  background: $white;
  box-shadow: 0 12px 13px rgba(#393e50, .13);
  text-align: left;
  width: 250px;
  box-sizing: border-box;

  @media $notebook {
    top: 10px; left: auto; right: 0;
  }

  @media $handheld {
    top: 10px; left: auto; right: 0;
  }
}

.header--seen-caption {
  font-size: 1.4rem;
  padding-left: 45px;
  line-height: height('i/seen-icon.png');
  margin-bottom: 25px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: width('i/seen-icon.png'); height: height('i/seen-icon.png');
    background: url(/i/seen-icon-blue.svg) no-repeat 50%;
    position: absolute;
    left: 5px; top: 0;
  }
}

.header--seen-list {
  .product + .product {
    margin-top: 1.5em;
  }

  .product {
    display: flex;
    font-size: 1.2rem;
    color: $blue;
    text-decoration: none;
    transition: all .25s ease;

    &:hover {
      color: $gray;
    }
  }

  .product--image {
    flex-shrink: 0;
    width: 40px; height: 40px;
    text-align: center;
    line-height: 40px;
    margin-right: 5px;

    img {
      max-width: 100%; max-height: 100%;
      vertical-align: middle;
    }
  }

  .product--type {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: .15em;
  }
}

.header--basket {
  padding-left: 54px; height: height('i/cart-icon.png');
  line-height: @height;
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
  transition: none;

  &:before {
    content: '';
    display: block;
    width: width('i/cart-icon.png'); height: 100%;
    background: url(/i/cart-icon.svg) no-repeat 0 50%;
    position: absolute;
    left: 0; top: 0;
  }

  &:not(.is-filled) {
    opacity: .5;
  }

  &.is-filled:hover {
    color: $blue;
  }

  &.is-filled:hover:before {
    background-image: url(/i/cart-icon-blue.svg);
  }

  @media $notebook {
    font-size: 0;
  }

  @media $handheld {
    font-size: 0;
  }

  @media $portrait-tablet {
    padding-left: 10px;
    width: width('i/cart-icon.png');
  }

  @media $landscape-mobile {
    padding-left: 10px;
    width: width('i/cart-icon.png');
  }

  @media $small-mobile {
    margin-left: 20px;
    padding-left: 0;
  }
}

.header--basket-counter,
.header--favorites-counter,
.header--compare-counter {
  display: block;
  width: 25px; height: @width;
  line-height: @height;
  border-radius: 50%;
  background: $blue;
  color: $white;
  font-family: $second-font;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  position: absolute;

  @media $mobile {
    width: 22px; height: @width;
  }
}

.header--basket-counter {
  left: 33px; top: -22px;

  @media $handheld {
    left: 24px;
  }

  @media $small-mobile {
    left: 18px; top: -10px;
  }
}

.header--favorites-counter {
  left: 30px; top: -22px;

  @media $mobile {
    top: -18px;
  }
}

.header--compare-counter {
  left: 18px; top: -20px;

  @media $mobile {
    top: -16px;
  }
}

.header--catalogue-link,
.header--mobile-catalogue-link {
  @extend .btn;
  width: 200px;
  color: $blue;
  background: transparent;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 45px;
  position: relative;
  transition: all .5s ease;
  cursor: default;

  .icon {
    left: 0; top: 50%;
    transform: translateY(-50%);
    width: 18px;

    &, &:before, &:after {
      display: block;
      height: 3px;
      background: $blue;
      position: absolute;
      transition: all .5s ease;
    }

    &:before, &:after {
      content: '';
    }

    &:before {
      width: 27px;
      bottom: 7px;
    }

    &:after {
      width: 9px;
      top: 7px;
    }
  }

  .menu-is-expanded & {
    color: $white;

    .icon, .icon:before, .icon:after {
      background: $white;
    }
  }
}

.header--mobile-catalogue-link {
  width: 40px; height: 40px;
  padding-left: 0;
  margin-left: 20px;

  .icon {
    left: 6px;
    pointer-events: none;
  }

  .menu-is-expanded & {
    .icon, .icon:before, .icon:after {
      background: $blue;
    }
  }

  @media $not-landscape-mobile {
    display: none;
  }

  @media $portrait-tablet {
    display: inline-block;
  }
}

.header--search {
  position: relative;

  @media $desktop {
    min-width: 384px;
  }

  @media $notebook {
    width: 176px;
    min-width: 176px;
  }

  @media $small-notebook {
    margin-top: -.5em;
  }

  @media $handheld {
    margin-top: -14px;
  }

  @media $portrait-tablet {
    display: none;
  }

  @media $landscape-mobile {
    display: none;
  }

  .inp {
    border: none;
    padding: 0 10px 0 55px;
    line-height: 40px;
    font-size: 1.4rem;

    @media $small-notebook {
      line-height: 30px;
    }

    @media $handheld {
      line-height: 30px;
      display: none;
    }
  }

  .btn {
    @extend .btn;
    box-sizing: content-box;
    width: width('i/search-icon.png'); height: height('i/search-icon.png');
    background: url(/i/search-icon.svg) no-repeat 50%;
    background-size: width('i/search-icon.png') auto;
    padding: 10px;
    font-size: 0;

    @media $desktop {
      position: absolute;
      left: -10px; top: 50%;
      transform: translateY(-50%);
    }

    @media $handheld {
      margin-left: -10px;
      margin-right: 15px;
    }
  }
}

.header--mobile-search {
  display: inline-block;
  vertical-align: middle;
  width: width('i/header-search-icon.png'); height: height('i/header-search-icon.png');
  background: url(/i/search-icon.svg) no-repeat;

  @media $not-landscape-mobile {
    display: none;
  }

  @media $mobile {
    display: none;
  }
}

.header--menu {
  @extend .menu, .horizontal-menu;

  > ul {
    @media $not-small-notebook {
      justify-content: flex-end;
    }

    @media $small-notebook {
      flex-wrap: wrap;
    }

    @media $handheld {
      flex-wrap: wrap;
    }
  }

  @media $not-small-notebook {
    >ul > li + li {
      margin-left: 5%;
    }
  }

  @media $small-notebook {
    > ul > li {
      margin-right: 5%;
      margin-bottom: .5em;
    }
  }

  @media $tablet {
    flex: 1;

    > ul > li {
      margin-right: 5%;
      margin-bottom: .5em;
    }
  }

  @media $landscape-mobile {
    > ul > li {
      margin-right: 4%;
    }

    flex: 1;
    margin-right: -4%;
  }

  a,
  span {
    font-size: 1.2rem;
    font-weight: $extra-bold;
    text-transform: uppercase;
  }

  > ul > li > a,
  > ul > li > span {
    padding: 5px 0;
  }

  li.is-hovered {
    > a,
    > span {
      color: $blue;
    }
  }

  @media $mobile {
    display: none;
  }

  .has-dropdown {
    position: relative;

    span {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: calc(100% + 6px); height: 4px;
        background: $blue;
        position: absolute;
        left: -3px; top: 100%;
        z-index: 2;
        max-height: 0;
        transition: all .25s ease;
      }
    }
  }

  .dropdown {
    position: absolute;
    left: -10px; top: 100%;
    background: $white;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;
    box-shadow: 0 10px 19px rgba(59, 64, 86, .18);
    overflow: hidden;

    a,
    span {
      display: block;
      text-transform: none;
      font-weight: normal;
      color: $blue;
    }

    a:hover {
      color: $black;
    }

    li + li {
      margin-left: 0;
      margin-top: .75em;
    }
  }

  .is-hovered .dropdown {
    opacity: 1;
    max-height: 200px;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .has-dropdown.is-hovered span:after {
    max-height: 4px;
  }
}


// Footer

.footer {
  background: $blue;
  font-size: 1.4rem;
  line-height: 1.35;
  padding-top: 50px;
  position: relative;
  z-index: 1;

  @media $small-mobile {
    padding-top: 40px;
  }

  &, a {
    color: $white;
  }
}

.footer--top,
.footer--bottom {
  main-grid();
}

.footer--top {
  @media $handheld {
    display: block;
    padding-left: 0;
    padding-right: 0;

    @media $not-small-mobile {
      .footer--group {
        main-grid();
      }
    }

    @media $small-mobile {
      .footer--group {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .footer--column_logo {
      grid-column: 1 / 4;
      grid-row: 1;
    }

    .footer--column_phones {
      grid-column: 1 / 4;
      grid-row: 2;
    }

    .footer--column_address {
      grid-column: 4 / 7;
      grid-row: 1 / 3;
      margin-left: -($grid-gap);
      margin-right: $grid-gap;
      padding-top: 15px;

      @media $small-mobile {
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
      }
    }

    .footer--column_menu {
      grid-column: 7 / 11;
      grid-row: 1 / 3;
      padding-top: 15px;
    }
  }

  @media $landscape-mobile {
    @media $not-small-mobile {
      .footer--column_phones {
        margin-top: -72px !important;
      }
    }

    .footer--column_address {
      grid-column: 6 / 11;
      grid-row: 1 / 2;
      margin-left: 0;
      margin-left: -($grid-gap / 2);
    }

    .footer--column_menu {
      grid-column: 6 / 11;
      grid-row: 2 / 3;
      margin-left: -($grid-gap / 2);
      margin-top: 0 !important;

      @media $small-mobile {
        margin-left: 0;
        margin-top: 25px !important;
      }
    }
  }
}

.footer--bottom {
  position: relative;
  padding-top: 135px;
  padding-bottom: 55px;

  @media $less-than-wrapper {
    padding-top: 180px;
  }

  @media $handheld {
    padding-bottom: 30px;
    padding-top: 145px;
    overflow: hidden;
  }

  @media $landscape-mobile {
    padding-top: 100px;

    .footer--content {
      margin-left: -($grid-gap / 2);
      margin-right: -($grid-gap / 2);
    }
  }

  @media $small-mobile {
    padding-top: 65px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: height('i/footer-bg.png');
    background: url(/i/footer-bg.png) no-repeat 50% 100%;
    position: absolute;
    bottom: 0; left: 0;
    z-index: -1;
    pointer-events: none;

    @media $less-than-wrapper {
      @media $desktop {
        background-position: 0 100%;
      }
    }

    @media $handheld {
      bottom: -15px;
    }

    @media $landscape-mobile {
      bottom: -25px;
    }

    @media $mobile {
      bottom: 0;
    }

    @media $small-mobile {
      bottom: -70px;
    }
  }
}

.footer--content {
  wrapper();
}

.footer--scroll-top {
  width: width('i/footer-lift.png'); height: height('i/footer-lift.png');
  background: url(/i/footer-lift.png) no-repeat 50% 100%;
  position: absolute;
  bottom: 100%; left: 50%;
  transform: translateX(-50%);
}

.footer--group {
  display: flex;
  justify-content: space-between;

  .footer--column:not(:first-child) {
    margin-top: 30px;
  }
}

.footer--column + .footer--column {
  margin-top: 40px;
}

.footer--logo {
  display: block;
  font-size: 0;
  width: width('i/mobil-market-logo-white.png');
  height: height('i/mobil-market-logo-white.png');
  background: url(/i/mobil-market-logo-white.svg) no-repeat 50% / contain;

  @media $small-mobile {
    width: 126px; height: 60px;
  }
}

.footer--phones {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: bold;
  min-height: 100px;

  @media $less-than-wrapper {
    min-height: 90px;
  }

  @media $handheld {
    min-height: 60px;
    font-size: 1.8rem;
  }

  @media $small-mobile {
    font-size: 1.4rem;
    min-height: 0;
  }
}

.footer--callback {
  font-family: $second-font;
  margin-bottom: 25px;
  line-height: 2;

  a {
    display: inline-block;
    text-decoration: none;
    line-height: 1.4;
    border-bottom: 1px solid rgba($white, .3);
    transition: all .25s ease;

    &:hover {
      border-bottom-color: $white;
    }
  }

  @media $small-mobile {
    display: none;
  }
}

.footer--worktime {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  min-height: 100px;
  white-space: pre;

  @media $less-than-wrapper {
    min-height: 90px;
  }

  @media $handheld {
    font-size: 1.8rem;
    min-height: 75px;
  }

  @media $landscape-mobile {
    min-height: 65px;
  }

  @media $small-mobile {
    font-size: 1.4rem;
    min-height: 0;
  }
}

.footer--address {
  line-height: 2;
  white-space: pre;
  min-height: 110px;

  @media $less-than-wrapper {
    min-height: 100px;
  }

  @media $handheld {
    white-space: normal;
    min-height: 161px;
  }

  @media $landscape-mobile {
    min-height: 80px;
  }

  @media $small-mobile {
    line-height: 1.35;
    min-height: 0;
    margin-top: 25px;
  }
}

.footer--socials {
  display: flex;
  align-items: center;

  .item {
    display: block;
  }

  .item + .item {
    margin-left: 18px;
  }

  for $social in 'vk' 'fb' 'twit' 'in' 'utb' {
    .item_{$social} {
      background: url('/i/sm-' + $social + '-white.svg') no-repeat 50%;
      width: width('i/sm-' + $social + '-white.svg');
      height: height('i/sm-' + $social + '-white.svg');
    }
  }

  .item_utb {
    background-size: 115% auto;
    background-position: 0 0;
  }

  @media $landscape-mobile {
    @media $not-small-mobile {
      display: none;
    }
  }

  @media $small-mobile {
    margin-top: 25px;
  }
}

.footer--menu-caption {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  min-height: 100px;

  @media $less-than-wrapper {
    min-height: 90px;
  }

  @media $handheld {
    font-size: 1.8rem;
    min-height: 75px;
  }

  @media $landscape-mobile {
    display: inline-block;
    border: none;
    outline: none;
    bordered-btn(2px, $white);
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: auto;
  }
}

.footer--menu {
  @extend .menu;
  min-height: 110px;

  @media $less-than-wrapper {
    min-height: 100px;
  }

  @media $handheld {
    min-height: 151px;
  }

  @media $landscape-mobile {
    overflow: hidden;
    min-height: auto;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;

    .footer--column_menu.is-expanded & {
      opacity: 1;
      max-height: 500px;
      padding-top: 30px;
    }
  }

  @media $small-mobile {
    font-size: 1.4rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: bold;

    @media $desktop {
      max-height: 65px;
    }

    @media $handheld {
      max-height: 100px;
    }

    @media $landscape-mobile {
      max-height: none;
    }
  }

  li {
    margin-bottom: 10px;
  }

  li:not(:nth-child(3n + 3)) {
    margin-right: 35px;
  }
}

.footer--payment {
  display: flex;
  align-items: center;

  @media $landscape-mobile {
    @media $not-small-mobile {
      display: none;
    }
  }

  @media $small-mobile {
    margin-top: 20px;
    display: block;
  }
}

.footer--payment-label {
  font-size: 1.6rem;
  line-height: 1.2;

  @media $handheld {
    @media $not-small-mobile {
      display: none;
    }
  }

  @media $small-mobile {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.footer--payment-items {
  display: flex;
  align-items: center;
  min-width: 130px;

  @media $desktop {
    margin-left: 45px;
  }

  .item + .item {
    @media $not-small-mobile {
      margin-left: 30px;
    }

    @media $small-mobile {
      margin-left: 25px;
    }
  }

  @media $small-mobile {
    img {
      max-height: 31px;
    }
  }
}

.footer--catalogue {
  @extend .menu, .justify-menu;
  margin-bottom: 30px;

  ul {
    align-items: flex-start;

    @media $handheld {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  @media $handheld {
    margin-bottom: 15px;

    li {
      margin-bottom: .5em;
      margin-left: 2.5%;
      margin-right: 2.5%;
    }
  }

  @media $desktop {
    li + li {
      margin-left: 10px;
    }
  }

  @media $small-mobile {
    display: none;
  }

  a {
    color: $black;
    font-weight: bold;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.footer--copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;

  @media $handheld {
    justify-content: center;
  }

  @media $landscape-mobile {
    justify-content: space-between;
    padding-left: (2 * $grid-gap);
    padding-right: (2 * $grid-gap);
  }

  @media $mobile {
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
  }

  @media $small-mobile {
    flex-direction: column;
    justify-content: center;
    padding-left: (2 * $grid-gap);
    padding-right: (2 * $grid-gap);
  }

  &, a {
    color: $black;
  }

  a {
    text-decoration: none;
  }

  .icon_shogo {
    vertical-align: middle;
    width: width('i/shogo-logo.png'); height: height('i/shogo-logo.png');
    background: url(/i/shogo-logo.png) no-repeat 50% / contain;

    @media $small-mobile {
      display: none;
    }
  }
}

.footer--copyright-item > * {
  display: inline-block;
}

.footer--archive {
  margin-left: 30px;

  @media $mobile {
    display: none;
  }
}

.footer--copyright-item + .footer--copyright-item {
  @media $not-small-mobile {
    margin-left: 45px;
  }

  @media $small-mobile {
    margin-top: .5em;
  }
}

@media $small-mobile {
  .footer--yamarket {
    margin-top: 1.5em;
  }
}


.moving-to-basket {
  transition: all .75s ease;
  z-index: 10000;
  transform-origin: top center;

  &.scale {
    transform: scale(.1);
  }
}


// Rotation warning

.rotate-device-warning {
  display: none;
  /* background: $black url(/i/rotate-device.png) no-repeat 50% 40%;
  width: 100%; height: 100%;
  position: fixed;
  left: 0; top: 0;
  z-index: 10000;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: $white;
  text-align: center;

  span {
    position: absolute;
    left: 50%; bottom: 20px;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  @media only screen and (max-device-width: 736px) and (min-device-width: 480px) and (orientation: landscape) {
    display: block;
  } */
}

/* @media only screen and (max-device-width: 736px) and (min-device-width: 480px) and (orientation: landscape) {
  body {
    height: 100vh;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 200%;
      background: $black;
      position: fixed;
      left: 0; top: 0;
    }
  }
} */
