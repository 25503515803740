
/*   -=========== Homepage ===========-   */

.homepage-rotator-section {
  main-grid();
  /* background: url(/i/style-pattern-1.png) no-repeat 100% 50%; */
  height: 598px;
  overflow: hidden;

  @media $small-mobile {
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.homepage-catalogue {
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  @media $small-notebook {
    grid-column: 1 / 4;
  }

  @media $tablet {
    grid-column: 1 / 4;
  }
}

.homepage-rotator {
  grid-column: 3 / 10;
  grid-row: 1;
  padding-left: 6vw;
  padding-right: 3vw;
  line-height: 0;

  @media $less-than-wrapper {
    grid-column: 3 / 8;
    padding-left: ($grid-gap / 2);
    padding-right: 0;
    margin-right: -5vw;
  }

  @media $notebook {
    margin-right: 0;
  }

  @media $small-notebook {
    grid-column: 4 / 11;
    margin-right: $grid-gap;
  }

  @media $tablet {
    grid-column: 4 / 11;
    margin-right: $grid-gap;
  }

  @media $portrait-tablet {
    grid-column-start: 1;
    grid-column-end: -1;
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
  }

  @media $landscape-mobile {
    grid-column-start: 1;
    grid-column-end: -1;
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
  }

  @media $small-mobile {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    border-top: 1px solid $gray;
  }

  &--slides {
    white-space: nowrap;
    overflow: hidden;
  }

  .item {
    display: inline-block;
    width: 100%;
  }

  .slick-dots {
    position: absolute;
    left: -5px; bottom: 50px;
    display: flex;

    @media $small-mobile {
      left: 20px; bottom: 20px;
    }

    li {
      list-style: none;
      font-size: 0;
    }

    button {
      @extend .btn;
      background: none;
      padding: 7px;

      &:before {
        content: '';
        display: block;
        width: 12px; height: @width;
        border-radius: 50%;
        background: #d7dae3;
      }
    }

    .slick-active button:before {
      background: $blue;
    }
  }
}

.homepage--daily-offer {
  grid-column: 10 / 12;
  grid-row: 1;
  place-self: self-start;
  margin-top: 20px;
  justify-self: end;

  @media $less-than-wrapper {
    grid-column: 9 / 11;
    margin-right: ($grid-gap / 2);
    margin-left: -($grid-gap);
  }

  @media $notebook {
    grid-column: 8 / 11;
    margin-left: $grid-gap;
  }

  @media $small-notebook {
    display: none;
  }

  @media $handheld {
    display: none;
  }
}

.daily-offer {
  width: 255px;
  padding: 30px 32px 25px 40px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    width: 105%; height: 105%;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    background: $white url(/i/daily-offer-bg.png) no-repeat;
    box-shadow: 0 0 49px 1px rgba(#3b4056, .18);
    border-radius: 0 27px 27px 27px;
    max-width: 100%; max-height: 100%;
    transition: all .25s ease;
  }

  &:hover:before {
    max-width: 105%; max-height: 105%;
  }
}

.daily-offer--caption {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: $gray;
  margin-bottom: 25px;
}

.daily-offer--slideshow {
  white-space: nowrap;
  overflow: hidden;
}

.daily-offer--image {
  display: block;
  max-height: 215px;
  text-align: center;
  margin-bottom: 25px;

  img {
    display: inline-block;
    height: 100%;
    max-height: 215px;
  }
}

.daily-offer--info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.daily-offer--name {
  font-size: 1.2rem;
  margin-bottom: 15px;

  a {
    text-decoration: none;
    color: $black;
  }
}

.daily-offer--old-price {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: $gray;
}

.daily-offer--price {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: $blue;
}

.daily-offer--buy-btn {
  buy-btn-prototype();
}


.homepage--shop-features {
  grid-column: 3 / 12;
  display: flex;
  justify-content: space-between;
  padding-left: 6vw;
  padding-right: 5vw;

  @media $less-than-wrapper {
    grid-column: 3 / 11;
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
    align-items: flex-end;
  }

  @media $small-notebook {
    grid-column: 4 / 11;
  }

  @media $tablet {
    grid-column: 4 / 11;
  }

  @media $landscape-mobile {
    grid-column-start: 1;
    padding-left: $grid-gap;
    padding-right: $grid-gap;
  }

  @media $small-mobile {
    display: none;
  }

  .item {
    box-sizing: border-box;
    font-size: 1.4rem;
    padding-left: 30px;
    padding-top: 9px;

    @media $less-than-wrapper {
      padding-bottom: 35px;
      min-height: 113px;
    }

    @media $notebook {
      &:nth-child(3) {
        display: none;
      }
    }

    @media $small-notebook {
      &:nth-child(2) {
        display: none;
      }
    }

    @media $handheld {
      padding-bottom: 15px;
      min-height: 0;

      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
  }

  .item--text {
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .item:not(.item_white) {
    color: $gray;

    .item--text {
      color: $light-gray;
    }
  }

  .item_white {
    color: $white;
    position: relative;

    .item--text {
      color: lighten($light-gray, 10);
    }

    &:before {
      content: '';
      display: block;
      width: width('i/style-pattern-3.png'); height: height('i/style-pattern-3.png');
      background: url(/i/style-pattern-3.png) no-repeat 100% 50%;
      position: absolute;
      left: -100px; bottom: 0;
      z-index: -1;

      @media $handheld {
        width: width('i/style-pattern-lite.png'); height: height('i/style-pattern-lite.png');
        background-image: url(/i/style-pattern-lite.png);
        background-position: 100% 100%;
        left: -150px;
      }

      @media $landscape-mobile {
        left: -140px;
      }
    }
  }

  .item_1 {
    background: url(/i/feature-1.svg) no-repeat 0 0 / 18px auto;
  }

  .item_2 {
    background: url(/i/feature-2.svg) no-repeat 0 0 / 20px auto;
  }

  .item_3 {
    background: url(/i/feature-3.svg) no-repeat 0 0 / 16px auto;
  }

  .item_4 {
    background: url(/i/feature-4.svg) no-repeat 0 0 / 18px auto;
  }

  .item--caption {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
}


.homepage--vitrine {
  background: $back-gray;
  padding-bottom: 100px;

  @media $small-mobile {
    padding-bottom: ($grid-gap / 2);
  }
}


.about-shop-section {
  main-grid();

  @media $not-notebook {
    background: url(/i/style-pattern-2.png) no-repeat 100% 50%;
  }
}

.about-shop-section--content {
  grid-column: 3 / 9;
  padding-top: 40px;
  padding-bottom: 30px;
  margin-left: -15px;

  @media $less-than-wrapper {
    grid-column: 1 / 7;
    margin-left: $grid-gap;
  }

  @media $notebook {
    grid-column: 1 / 8;
  }

  @media $handheld {
    grid-column: 1 / 11;
  }

  @media $small-mobile {
    margin-left: 0;
    margin-right: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.about-shop-section--caption {
  @extend .h1;
  margin-bottom: 30px;

  @media $handheld {
    @media $not-small-mobile {
      text-align: center;
    }
  }
}

.about-shop-section--text {
  color: #676767;
  font-size: 1.6rem;
  line-height: 1.5;

  p + p {
    margin-top: 1em;
  }

  @media $small-mobile {
    font-size: 1.4rem;
  }
}

.about-shop-section--illustration {
  grid-column: 9 / 12;

  img {
    margin-top: -28px;
    margin-bottom: -60px;
  }

  @media $less-than-wrapper {
    grid-column: 7 / 10;
    margin-left: $grid-gap;
  }

  @media $notebook {
    grid-column: 8 / 11;
  }

  @media $handheld {
    display: none;
  }
}


.brands-section {
  main-grid();
  background: $back-gray;
  padding-top: 65px;
  padding-bottom: 80px;

  @media $small-mobile {
    padding-right: 35px;
    padding-bottom: 35px;
  }
}

.brands-section--content {
  wrapper();
}

.brands-section--caption {
  @extend .h1;
  margin-bottom: 50px;

  @media $handheld {
    @media $not-small-mobile {
      text-align: center;
    }
  }
}

.brands-list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media $not-small-mobile {
    .item + .item {
      margin-left: 30px;
    }
  }

  @media $landscape-mobile {
    align-items: flex-end;

    .item img {
      max-height: 50px;
    }
  }

  @media $small-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;

    .item {
      max-width: calc(50% - 30px);
      margin-bottom: 30px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .item img {
      max-height: 32px;
    }
  }
}
