
/*   -=========== Profile ===========-   */

.profile-page {
  @media $not-small-mobile {
    margin-bottom: 120px;
  }

  @media $small-mobile {
    margin-bottom: 60px;
  }
}

.profile-page--layout {
  display: flex;

  @media $small-mobile {
    flex-direction: column;
  }
}

.profile-page--aside {
  @media $not-small-mobile {
    flex-basis: 210px;
    padding-top: 60px;
  }

  @media $small-mobile {
    padding-top: 10px;

    .selectric .label {
      font-size: 1.4rem;
      line-height: 41px;
    }
  }
}

.profile-page--content {
  flex: 1;

  @media $not-small-mobile {
    margin-left: 5vw;
  }

  @media $small-mobile {
    margin-top: 30px;
  }
}

.profile-page--caption {
  margin-bottom: 35px;
}


.profile-page--empty-history {
  color: $gray;
  font-size: 1.4rem;
}


// Orders history

.orders-table {
  font-size: 1.4rem;
  color: $gray;

  .spacer {
    height: 30px;
  }
}

.orders-table--header {
  background: $back-gray;

  th {
    padding: 12px 20px;
    text-align: left;
  }

  th:last-child {
    text-align: right;

    @media $small-mobile {
      text-align: left;
      padding-top: 0;
    }
  }

  .icon_check {
    width: width('i/check-icon.png'); height: height('i/check-icon.png');
    background: url(/i/check-icon.png) no-repeat 50%;
    margin-right: 10px;
    vertical-align: middle;
  }

  @media $small-mobile {
    display: flex;
    flex-direction: column;
  }
}

.orders-table--header-empty-cell {
  padding: 0 !important;
}

.orders-table--body {
  th {
    padding: 12px 20px;
    text-align: left;
  }

  @media $small-mobile {
    display: none;
  }
}

.orders-table--product {
  td {
    padding: 20px;
  }

  @media $small-mobile {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 30px);
  }
}

.orders-table--product-description {
  color: $blue;

  @media $small-mobile {
    flex-basis: 100%;
  }
}

@media $small-mobile {
  .orders-table--product-sum,
  .orders-table--product-amount {
    flex-basis: 50%;
    padding-top: 0 !important;
    box-sizing: border-box;
  }
}

.orders-table--product-category {
  text-transform: uppercase;
  font-weight: bold;
}

.orders-table--delivery {
  td {
    padding: 20px;
    font-weight: bold;
  }
}

.orders-table--total-row {
  td {
    padding: 20px;
    border-top: 2px solid $back-gray;
  }
}

.orders-table--total {
  text-align: right;
  color: $blue;
  text-transform: uppercase;
  font-size: 1.8rem;
}

.orders-table--refund {
  display: block;
  padding-left: 60px;
  line-height: 24px;
  text-decoration: none;
  color: $gray;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 44px; height: 24px;
    box-sizing: border-box;
    border: 2px solid $gray;
    border-radius: 12px;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    display: block;
    width: 24px; height: @width;
    background: $gray;
    border-radius: 50%;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
  }
}
