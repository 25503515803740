
/*   -=========== Contact ===========-   */

.contact-page--caption {
  margin-bottom: 15px;
}

.contact-page--content {
  grid-column: 3 / 7;
  margin-left: ($grid-gap / 2);
  color: $gray;
  font-size: 1.4rem;
  padding-bottom: 30px;

  @media $notebook {
    grid-column: 1 / 6;
  }

  @media $handheld {
    grid-column: 1 / 6;
  }

  @media $mobile {
    grid-column: 1 / 11;
    margin-left: 0;
  }
}

.contact-page--map {
  grid-column: 7 / 13;
  margin-right: -($grid-gap / 2);

  @media $notebook {
    grid-column: 6 / 11;
  }

  @media $handheld {
    grid-column: 6 / 11;
  }

  @media $mobile {
    grid-column: 1 / 11;
    grid-row: 2;
    height: 100vw;
    margin-left: -($grid-gap / 2);
  }

  .map {
    height: 100%;
  }
}

.contact-page--group_address {
  font-weight: bold;
  line-height: 1.35;
}

.contact-page--group + .contact-page--group {
  margin-top: 1.5em;
}

.contact-page--group {
  .item--caption {
    color: $blue;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: .35em;
  }

  .item + .item {
    margin-top: 1.5em;
  }

  a {
    color: $blue;
    text-decoration: none;
  }
}

.contact-page--group-caption {
  color: $blue;
  margin-bottom: .35em;
  text-transform: uppercase;
  font-weight: bold;
}

.contact-page--group_emails,
.contact-page--group_requisites {
  p {
    line-height: 1.35;
  }
}
