
/*   -=========== Forms ===========-   */

// Base

input,
textarea {
  font: inherit;
}

.form {
  span.required {
    color: $warning-color;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: .75em 0;
  line-height: 1.25em;
  color: $gray;
  background: transparent;
  border-bottom: 2px solid $gray;
  transition: all .25s ease;
  font-size: 1.2rem;

  &:focus {
    color: $blue;
    border-color: $blue;

    &::placeholder {
      color: $blue;
    }
  }

  .form-field.error > & {
    border-color: $warning-color;
    color: $warning-color;
  }
}

.checkbox,
.radio {
  padding-left: 32px;
  position: relative;
  font-size: 1.2rem;
  padding-top: .35em;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:before {
    content: '';
    display: block;
    width: 20px; height: @width;
    box-sizing: border-box;
    position: absolute;
    left: 0; top: 0;
    border: 2px solid $gray;
    background: $white;
    border-radius: 50%;
    transition: border-color .25s ease;
  }

  &:after {
    content: '';
    display: block;
    width: 10px; height: @width;
    position: absolute;
    left: 5px; top: 5px;
    background: $blue;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &.is-checked {
    &:before {
      border-color: $blue;
    }

    &:after {
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  padding: 0 46px;
  position: relative;

  @media $mobile {
    padding: 0 40px;
  }

  .inp {
    vertical-align: middle;
    border: none;
    box-sizing: border-box;
    width: 41px; height: @width;
    line-height: @height;
    border-radius: 50%;
    border: 2px solid $yellow;
    padding: 0;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: $gray;
  }

  .spinner-up,
  .spinner-down {
    @extend .btn;
    box-sizing: border-box;
    vertical-align: middle;
    width: 40px; height: @width;
    line-height: 40px;
    text-align: center;
    background: transparent;
    font-family: $second-font;
    font-size: 2.4rem;
    color: $gray;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .spinner-down {
    left: 0;
  }

  .spinner-up {
    right: 0;
  }

  .spinner-unit {
    position: absolute;
    left: 50%; top: 100%;
    transform: translateX(-50%);
    margin-top: 3px;
    font-size: 1.4rem;
  }
}


.form-row {
  clearfix();

  + .form-row {
    .one-column-form & {
      margin-top: 1em;
    }

    .two-columns-form & {
      margin-top: 1.5em;
    }
  }
}

.form-label {
  .one-column-form & {
    margin-bottom: .15em;
  }

  .two-columns-form & {
    float: left;
  }
}

.form-field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1rem;
    line-height: 1.1;
    color: $warning-color;
    position: absolute
    left: 0; top: 100%;
    width: 100%;

    @media $not-small-mobile {
      margin-top: 5px;
    }
  }

  .checkbox + .errorMessage {
    padding-left: 32px;
  }

  &.error {
    .errorMessage {
      display: block;
    }
  }
}

.form-field--hint {
  font-size: 1.1rem;
  line-height: 1.2;
  color: $gray;
  margin-top: .25em;
}

.form-hint {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #000;

  span.required {
    font-size: 1.8rem;
    color: #000;
  }

  .one-column-form & {
    margin-top: 2em;
  }

  .two-columns-form & {
    margin-top: 1.25em;
  }
}

.form-submit {
  .one-column-form & {
    margin-top: 2em;
    text-align: center;

    @media $small-mobile {
      margin-top: 1.5em;
    }
  }

  .two-columns-form & {
    margin-top: 1.25em;
  }

  /* .submit-button {
    @extend .btn, .rounded-btn;
    padding: 0 50px 0 25px;
    font-size: 1.5rem;
    box-shadow: 0 4px 20px rgba(#000, .25);
    position: relative;
  } */
}


// Order form

.order-form {
  .form-submit {
    text-align: right;
  }

  .submit-button {
    @extend .btn, .yellow-btn, .rounded-btn;
    line-height: 40px;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: bold;
    width: 165px;
  }

  .form-row + .form-row {
    margin-top: 30px;
  }

  .selectric {
    height: 37px;
    line-height: 35px;
    border: none;
    border-bottom: 2px solid $gray;

    .label {
      font-size: 1.4rem;
      height: 35px;
      line-height: @height;
      margin-left: 0;
      margin-right: 30px;
    }

    .button:before {
      left: auto;
      right: 0;
      margin-right: 2px;
    }
  }

  .selectric-items {
    li {
      font-size: 1.2rem;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.order-form--group {
  position: relative;
  padding-top: 45px;

  legend {
    position: absolute;
    left: 0; top: 0;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    color: $blue;
  }
}

.order-form--group_delivery-address {
  padding-top: 30px;
}

.order-form--group + .order-form--group {
  margin-top: 40px;
}

.order-form--group + .form-row {
  margin-top: 30px;
}

.order-form--fieldset {
  display: flex;
  align-items: stretch;
  font-size: 1.2rem;
  color: $gray;

  @media $small-mobile {
    flex-direction: column;
  }

  .fieldset--caption {
    width: 162px;
    font-size: 1.2rem;
    font-weight: bold;
    color: $gray;
    flex-shrink: 0;

    @media $small-mobile {
      margin-bottom: 10px;
    }
  }

  .radio-input .radio {
    display: block;
    position: relative;
    padding-right: 25px;
  }

  .radio-input + .radio-input {
    margin-top: 10px;
  }

  .inp_small {
    border: 2px solid $gray;
    border-radius: 20px;
    width: 62px;
    text-align: center;
    margin-right: 10px;

    @media $not-small-mobile {
      margin-top: -12px;
    }

    &:focus {
      border-color: $blue;
    }
  }
}

.order-form--fieldset + .order-form--fieldset {
  margin-top: 40px;
}

.order-form--fieldset_column {
  flex-direction: column;

  .order-form--group {
    padding-top: 10px;
  }
}

.order-form--fieldset_delivery-price {
  &, .fieldset--caption {
    color: $blue;
  }

  .form-row {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: -4px;
  }
}

.form-row_agreement {
  .checkbox {
    font-size: 1rem;

    &, a {
      color: $gray;
    }
  }
}

.order-form--price {
  font-size: 1.2rem;
  font-weight: bold;
  color: $gray;
}

.order-form--price + .order-form--price {
  margin-top: 1em;
}

.order-form--price {
  display: flex;
  align-items: flex-end;
}


.order-form--price-label {
  width: 175px;
  flex: 1;

  @media $not-small-mobile {
    padding-left: 32px;
  }

  @media $small-mobile {
    padding-right: 10px;
  }
}

.order-form--price-value {
  flex-shrink: 1;
  flex-basis: 165px;
  margin-bottom: -.1em;
  font-size: 1.4rem;
}

.order-form--price_total {
  color: $blue;

  .order-form--price-value {
    font-size: 1.8rem;
    margin-bottom: -.2em;
  }
}

.order-form .radio-form-row + .form-row {
  margin-top: 15px;
}

.form-input-hint {
  position: absolute;
  right: 0; top: 0;
  width: 20px; height: 20px;
  background: url(/i/question-icon.png) no-repeat 50%;
  cursor: pointer;
}

.form-input-hint--content {
  display: none;
  position: absolute;
  left: 100%; top: 100%;
  cursor: default;
  padding: 25px 40px 35px 30px;
  box-shadow: 0 12px 13px rgba(#393e50, .13);
  min-width: 250px;
  font-size: 1.2rem;
  line-height: 1.5;
  background: $white;
  z-index: 10;

  p + p {
    margin-top: 1em;
  }

  @media $mobile {
    left: auto; right: -5px; top: 100%;
    box-sizing: border-box;

    @media $small-mobile {
      width: calc(100vw - 20px);
    }
  }
}

.form-input-hint--close {
  @extend .btn;
  width: 30px; height: 30px;
  position: absolute;
  top: 18px; right: 10px;
  transform: rotate(45deg);
  cursor: pointer;
  background: transparent;

  &:before, &:after {
    content: '';
    display: block;
    background: $blue;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 15px; height: 2px;
  }

  &:after {
    width: 2px; height: 15px;
  }
}

.form-input-info {
  display: none;
  font-size: 1rem;

  p {
    margin-top: .75em;
  }

  .is-checked & {
    display: block;
  }
}


// Auth form

.auth-form {
  .inp {
    border-bottom-color: $white;
    color: $white;
    font-size: 1.6rem;
    padding-top: .25em;
    padding-bottom: .25em;

    &::placeholder {
      color: $white;
    }
  }

  .form-row + .form-row {
    margin-top: 25px;
  }

  .form-row + .form-row_remember {
    margin-top: 30px;
  }

  .form-row_remember {
    font-size: 1.2rem;

    a {
      display: inline-block;
      margin-top: .35em;
    }
  }

  .checkbox {
    &:before {
      border-color: $white;
      background: transparent;
    }

    &:after {
      background: $white;
    }
  }

  .form-submit {
    margin-top: 60px;
  }

  .submit-button {
    @extend .btn, .yellow-btn, .rounded-btn;
    width: 155px;
    line-height: 40px;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .submit-button_autowidth {
    width: auto;
    padding-left: 35px;
    padding-right: 35px;
  }

  .errorSummary {
    font-size: 1.2rem;
    margin-bottom: 2em;

    ul {
      margin-top: .5em;
    }

    li {
      margin-left: 16px;
    }

    li + li {
      margin-top: .5em;
    }
  }

  .form-field.error > .inp,
  .form-field.error > .textarea {
    border-color: $yellow;
    color: $yellow;
  }

  .form-field .errorMessage {
    color: $yellow;
  }
}


// Profile form

.profile-form {
  width: 100%;
  max-width: 490px;

  .inp:not(:focus) {
    border-bottom-color: $back-gray;
  }

  .form-label {
    color: $gray;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .form-radio + .form-radio {
    margin-top: 10px;
  }

  .form-submit {
    text-align: left;
  }

  .submit-button {
    @extend .btn, .yellow-btn, .rounded-btn;
    line-height: 40px;
    padding: 0 20px;
    font-size: 1.4rem;
    font-weight: bold;
  }
}


// Callback form

.callback-form {
  .form-hint {
    font-size: 1rem;

    .required {
      line-height: .5;
    }
  }

  .submit-button {
    @extend .btn, .yellow-btn, .rounded-btn, .wide-btn;
    line-height: 40px;
    font-size: 1.4rem;
    font-weight: bold;
  }
}


// Fast order form

.fast-order-form {
  .form-hint {
    font-size: 1.1rem;
    padding-left: 32px;

    p + p {
      margin-top: .5em;
    }

    &, a {
      color: $gray;
    }
  }

  .submit-button {
    @extend .btn, .yellow-btn, .rounded-btn;
    line-height: 40px;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0 20px 0 65px;
    background-image: url(/i/cart-icon-white.png);
    background-repeat: no-repeat;
    background-position: 24px 50%;
    text-transform: lowercase;
  }
}


// Question form

.question-form {
  .submit-button {
    @extend .btn, .yellow-btn, .rounded-btn;
    line-height: 40px;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0 20px;
    text-transform: lowercase;
  }
}
