
/*   -=========== Filter ===========-   */

.mobile-filters-link {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 18px;
  font-size: 1.4rem;

  @media $mobile {
    order: 2;
    margin-left: 15px;
  }

  @media $small-mobile {
    font-size: 0;
    width: 40px;
    padding: 0;
    background: $blue url(/i/filter-icon.svg) no-repeat 50%;
  }
}

.mobile-filters-header {
  @media $not-small-handheld {
    display: none;
  }

  background: $blue;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: $white;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
}

.mobile-filters-close {
  @extend .btn;
  width: 35px; height: 35px;
  position: absolute;
  right: 15px; top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  background: transparent;

  &:before, &:after {
    content: '';
    display: block;
    background: $white;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 16px; height: 2px;
  }

  &:after {
    width: 2px; height: 16px;
  }
}


.filter {
  padding-left: 27px;
  padding-right: 27px;

  @media $small-handheld {
    display: none;
    box-sizing: border-box;
    width: 100%; height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
    background: $white;
    overflow-y: auto;
    position: fixed;
    top: 0; left: 0;
    z-index: 1000;
  }
}

.filter--items {
  border-bottom: 2px solid $blue;
  padding-bottom: 25px;
  margin-bottom: 20px;

  .item + .item {
    margin-top: 50px;
  }

  .item--head {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
    position: relative;
    padding-right: 18px;
  }

  .js-toggle-filter-item-content {
    cursor: pointer;
  }

  .item--body {
    overflow: hidden;
    color: $gray;
    transition: all .25s ease;
  }

  .item--toggler {
    display: block;
    width: 14px; height: 14px;
    position: absolute;
    right: 0; top: .05em;
    transition: all .25s ease;

    &:before, &:after {
      content: '';
      display: block;
      background: $blue;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity .25s ease;
    }

    &:before {
      width: 100%; height: 2px;
    }

    &:after {
      width: 2px; height: 100%;
    }
  }

  .item:not(.is-expanded) {
    .item--toggler {
      transform: rotate(-90deg);
    }

    .item--body {
      opacity: 0;
      max-height: 0;
    }
  }

  .item.is-expanded {
    .item--toggler {
      &:after {
        opacity: 0;
      }
    }

    .item--body {
      padding-top: 20px;
      max-height: 10000px;
    }
  }

  .filter-checkbox-item {
    position: relative;
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: $gray;

    &:after {
      content: ''
      display: block;
      width: 12px; height: 10px;
      background: url(/i/check-icon.png) no-repeat 50%;
      position: absolute;
      right: 0; top: .25em;
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.is-checked {
      font-weight: bold;

      &:after {
        opacity: 1;
      }
    }

    input {
      display: none;
    }
  }

  .item--toggle-all-link {
    @extend .btn;
    background: transparent;
    color: $blue;
    border-bottom: 1px solid rgba($blue, .3);
    line-height: 1.4;
    margin-top: 15px;
    transition: all .25s ease;

    display: none;

    &:hover {
      border-bottom-color: $blue;
    }
  }

  .checkbox-item:nth-child(16) ~ .item--toggle-all-link {
    display: inline-block;
  }

  .checkbox-item + .checkbox-item {
    margin-top: 15px;
  }

  .item--body_collapsible-list:not(.is-expanded) {
    .checkbox-item:nth-child(10) ~ .checkbox-item {
      display: none;
    }

    .item--toggle-all-link .expanded-text {
      display: none;
    }
  }

  .item--body_collapsible-list.is-expanded {
    .item--toggle-all-link .collapsed-text {
      display: none;
    }
  }

  .item--body_colors {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter-colorbox-item {
    display: inline-block;
    width: 26px; height: @width;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 19px; height: @width;
      border: 0 solid $white;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &.checked:before,
    &.is-checked:before {
      border-width: 2px;
    }

    input {
      display: none;
    }

    &[title="белый"] {
      box-shadow: inset 0 0 0 2px $black;

      &.checked:before,
      &.is-checked:before {
        width: 15px; height: @width;
        border-color: $black;
      }
    }
  }

  .item--body_slider {
    .slider-values {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;
    }

    .slider-group {
      display: flex;
      align-items: center;
    }

    .slider-group + .slider-group {
      margin-left: 10px;
    }

    .slider-label {
      font-size: 1.4rem;
    }

    .inp {
      font-size: 1.4rem;
      border: 2px solid $blue;
      border-radius: 20px;
      line-height: 36px;
      padding: 0 5px;
      box-sizing: border-box;
      max-width: 77px;
      text-align: center;
      margin-left: 7px;
    }
  }

  .item--slider {
    height: 2px;
    background: $back-gray;
    position: relative;
    margin-bottom: 27px;
    margin-left: 6px;
    margin-right: 6px;

    .ui-slider-range {
      height: 100%;
      position: absolute;
      background: $blue;
    }

    .ui-slider-handle {
      display: block;
      width: 6px; height: 6px;
      background: $white;
      border: 2px solid $blue;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      outline: none;
    }
  }

  .item--slider-results {
    font-size: 1.4rem;

    .btn {
      @extend .btn;
      background: transparent;
      display: inline-block;
      font-size: 1.4rem;
      line-height: 1.4;
      color: $blue;
      border-bottom: 1px solid rgba($blue, .3);
      transition: all .25s ease;
      margin-top: 15px;

      &:hover {
        border-bottom-color: $blue;
      }
    }
  }
}

.filter--reset-button {
  @extend .btn;
  width: 100%;
  background: transparent;
  text-align: left;
  font-size: 1.4rem;
  color: $gray;
  position: relative;
  margin-bottom: 35px;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    right: 5px; top: 50%;
    background: $gray;
    width: 2px; height: 16px;
  }

  &:before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &:after {
    transform: translateY(-50%) rotate(45deg);
  }
}

.filter--submit-button {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 40px;
  font-size: 1.4rem;
  padding: 0 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
